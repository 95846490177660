import React from "react";
import StoryBox from "./StoryBox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Phase = (props) => {

  return <Row className="phase text-left">
  <Col>
    <h2 className="pt-2">{props.title}</h2>
    {/*<p className="p1">{props.description}</p>*/}
    <Row>
        {props.stories.map((story, index) =>
          <Col lg={12} key={"story-box-" + index}>
            <StoryBox {...story} highlights={props.highlights}/>
          </Col>
        )}
    </Row>
    </Col>
  </Row>
}

export default Phase;
