import React, {useContext, useState} from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowRight, faArrowUp, faTrash, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ScrollTo} from "react-scroll-to";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/cjs/Modal";
import {Context} from "../../state/Store";

export const TYPE_STORY = "recits";
export const TYPE_ACTIVITY = "activite";
export const TYPE_EXPERIMENTATION = "experimentation";

const ExperimentationCard = (props) => {

  const [state] = useContext(Context);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [newCommentOpen, setNewCommentOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [showNoteDeleteModal, setShowNoteDeleteModal] = useState(false);
  const [showAnalysisDeleteModal, setShowAnalysisDeleteModal] = useState(false);

  return <>
    { props.type === TYPE_STORY
      ? <Card className="experimentation-card experimentation-card-story">
          <Card.Body>
            <div className="trash align-right">
              <FontAwesomeIcon icon={faTrash} size={"1x"} onClick={() => setShowNoteDeleteModal(true)}/>
            </div>
            <p><Link className="story-link" to={"/recits/" + props.data.story._id}>Récit {props.data.story.phase.number}.{props.data.story.number}</Link> - {props.data.story.title}</p>
            <br/>
            <p className="p1">« {props.data.text} »</p>
            <div className="align-right">
              {props.data.comments.length > 0
                ? <span style={{cursor: "pointer"}} onClick={() => {
                  setCommentsOpen(!commentsOpen);
                  if (commentsOpen) setNewCommentOpen(false);
                }}>
                <b>
                  <FontAwesomeIcon icon={commentsOpen ? faArrowDown : faArrowRight} size={"1x"}/> &nbsp;
                  {props.data.comments.length} note{props.data.comments.length >1 && "s"} associée{props.data.comments.length >1 && "s"}
                </b>
              </span>
                : <span className="add-story-note-action" onClick={() => setNewCommentOpen(!newCommentOpen)}>
                    <FontAwesomeIcon icon={newCommentOpen ? faArrowDown : faPlus} size={"sm"}/> <b> ajouter une note</b>
                  </span>}
            </div>

            <Collapse in={commentsOpen}>
              <div>
                {props.data.comments?.map(c =>
                  <div className="collapsed-story-note">
                    <p className="p1">{c}</p><br/>
                    <span className="collapsed-story-note-action" onClick={() => props.onRemoveComment(props.data._id, c)}>
                      <FontAwesomeIcon icon={faTimes} size={"sm"}/> <b>supprimer la note</b>
                    </span>
                    <span className="collapsed-story-note-action" onClick={() => setNewCommentOpen(!newCommentOpen)}>
                      <FontAwesomeIcon icon={newCommentOpen ? faArrowDown : faPlus} size={"sm"}/> <b> ajouter une note</b>
                    </span>
                  </div>)
                }
              </div>
            </Collapse>

            <Collapse in={newCommentOpen}>
              <div>
                <div className="new-note">
                  <textarea placeholder={"Que vous évoque cet extrait ?"} value={newComment} onChange={(event) => setNewComment(event.target.value)}/>
                  <span className="collapsed-story-note-action" onClick={() => { props.onAddComment(props.data._id, newComment); setCommentsOpen(true); setNewCommentOpen(false); }}><b>valider</b></span>
                  <span className="collapsed-story-note-action cancel" onClick={() => { setNewCommentOpen(false); setNewComment(""); }}><b>annuler</b></span>
                </div>
              </div>
            </Collapse>
          </Card.Body>
        </Card>
    : props.type === TYPE_ACTIVITY
      ? <Card className="experimentation-card experimentation-card-activity">
          <Card.Body>
            <div className="trash align-right" onClick={() => setShowAnalysisDeleteModal(true)}>
              <FontAwesomeIcon icon={faTrash} size={"1x"}/>
            </div>
            <p className="p1">
              <Link className="story-link" to={state.user?.analyzedActivities.includes(props.activity._id)
                ? "/activite"
                : "/exploration/reponses/" + props.activity._id}>
                Exploration {props.activity.number}
              </Link> - {props.activity.title}
            </p>
            <Row>
              <Col>
                <p className="p2">{props.data.question}</p>
                <p className="p1">Votre réponse : <b>{props.data.answer}</b></p>
              </Col>
              {props.data.toKeep
                ?
                <Col>
                  <p className="p2">✓ A conserver :</p>
                  <p className="p1">« {props.data.toKeep} »</p>
                </Col>
                : props.data.toChange
                  ? <Col>
                      <p className="p2">→ A faire évoluer :</p>
                      <p className="p1">« {props.data.toChange} »</p>
                    </Col>
                  : ""
               }
            </Row>
          </Card.Body>
        </Card>
    : props.type === TYPE_EXPERIMENTATION
      ? <Card className="experimentation-card experimentation-card-experimentation">
        <Card.Body>
          <h2>Expérimentation</h2>
          {props.data && <div>
            <p className="p1"><b>Idée d'expérimentation du {new Date(props.data[0]?.createdAt).toLocaleDateString('fr-FR')} :</b></p>
            <p className="p1">{props.data[0]?.description}</p>
          </div>}
          <hr className="experimentation-card-separator"/>
          <Collapse in={props.experimentationDetailOpen}>
            <div>
              {props.data?.map((e, i) => i > 0 && <div>
                <p className="p1"><b>Idée d'expérimentation du {new Date(e.createdAt).toLocaleDateString('fr-FR')} :</b></p>
                <p className="p1">{e.description}</p>
                <hr className="experimentation-card-separator"/>
              </div>)}
            </div>
          </Collapse>
          <ScrollTo>
            {({scroll}) => (
              <span className={"experimentation-card-experimentation-action"}
                    onClick={() => scroll({y: props.newExperimentationRef.current.offsetTop, smooth: true})}>
                <FontAwesomeIcon icon={newCommentOpen ? faArrowDown : faPlus} size={"sm"}/> <b>ajouter une nouvelle idée</b>
              </span>
            )}
          </ScrollTo>
          {(props.data && props.data.length > 1) &&
            <span className={"experimentation-card-experimentation-action align-right"} onClick={() => props.setExperimentationDetailOpen(!props.experimentationDetailOpen)}>
              {props.experimentationDetailOpen
                ? <b><FontAwesomeIcon icon={faArrowUp} size={"1x"}/> &nbsp; replier</b>
                : <b><FontAwesomeIcon icon={faArrowRight} size={"1x"}/> &nbsp; lire la suite</b>
              }
            </span>
          }
        </Card.Body>
      </Card>
    : ""}
    <Modal
      show={showNoteDeleteModal}
      onHide={() => setShowNoteDeleteModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5>Etes-vous sûr de vouloir supprimer cette note ?</h5>
        <span className="delete-modal-action cancel" onClick={() => setShowNoteDeleteModal(false)}>Annuler</span>
        <span className="delete-modal-action" onClick={() => {
          props.onRemoveHighlight(props.data._id);
          setShowNoteDeleteModal(false);
        }}>Supprimer</span>
      </Modal.Body>
    </Modal>

    <Modal
      show={showAnalysisDeleteModal}
      onHide={() => setShowAnalysisDeleteModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5>Etes-vous sûr de vouloir supprimer cette exploration ?</h5>
        <span className="delete-modal-action cancel" onClick={() => setShowAnalysisDeleteModal(false)}>Annuler</span>
        <span className="delete-modal-action" onClick={() => {
          props.onRemoveAnalysis(props.data._id);
          setShowAnalysisDeleteModal(false);
        }}>Supprimer</span>
      </Modal.Body>
    </Modal>
  </>

}

export default ExperimentationCard;

