import React, {useContext, useEffect, useState} from 'react';
import {hot} from 'react-hot-loader/root'
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {SET_USER, UNSET_USER} from "./state/Reducer";
import {Context} from "./state/Store";
import ScrollToTop from "react-scroll-to-top";

import Landing from "./components/Landing";
import Stories from "./components/Stories/Stories";
import StoriesRelatedToActivities from "./components/Stories/StoriesRelatedToActivities";
import StoryDisplay from "./components/Stories/StoryDisplay";
import BarometerNew from "./components/Barometer/BarometerNew";

import Quizz from "./components/Quizz/Quizz";

import Dashboard from "./components/Dashboard/Dashboard";
import Activities from "./components/Activity/Activities";
import ActivityDisplay from "./components/Activity/ActivityDisplay";
import Experimentation from "./components/Experimentation/Experimentation";
import ActivitySubmission from "./components/Activity/ActivitySubmission";
import Auth, {TYPE_ACTIVITY, TYPE_BAROMETER, TYPE_EXPERIMENTATION} from "./components/Auth/Auth";
import Account from "./components/Auth/Account";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.css';
import logo from 'img/logo_blue.svg';
import user from 'img/user.svg';


import {
  footer_contact,
  header_activity,
  header_barometer,
  header_experimentation,
  header_stories,
  header_tableau
} from "./texts";
import {email} from "./texts/_params";
import ResetPassword from "./components/Auth/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Legal from "./components/Legal";
import UpdatedLegalModal from "./components/UpdatedLegalModal";
import ConfirmEmail from "./components/Auth/ConfirmEmail";

const App = () => {

  const [state, dispatch] = useContext(Context);
  const [page, setPage] = useState("");
  const [navExpanded, setNavExpanded] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(false);
  const [cguLabel, setCguLabel] = useState("");
  const [pdcLabel, setPdcLabel] = useState("");
  const [mlLabel, setMlLabel] = useState("");

  const [headerColor, setHeaderColor] = useState("transparent")


  axios.interceptors.request.use(function (config) {
    const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
    const apiRegex = /\/api.*/;
    if ( !isAbsoluteURLRegex.test(config.url) && !apiRegex.test(config.url) ) {
      config.url = require("url-join")('/api', config.url);
    }
    config.headers['CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    return config;
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response?.status === 401) {
      dispatch({type: UNSET_USER});
    }
    if (error.response?.status === 403) {
      return Promise.reject(error);
    }
    throw error;
  });

  useEffect(() => {
    axios.get(
      '/auth/user'
    ).then((res) => {
      if (res.data)
        dispatch({type: SET_USER, payload: res.data});
      setFetchedUser(true);
    }).catch(() => {
      setFetchedUser(true);
    });
    axios.get('/legal/cgu/label').then((res) => setCguLabel(res.data));
    axios.get('/legal/pdc/label').then((res) => setPdcLabel(res.data));
    axios.get('/legal/ml/label').then((res) => setMlLabel(res.data));
  }, []);

  const useUpdateHeader = () => {
    let location = useLocation();
    useEffect(() => {
      setNavExpanded(false);
      switch (location.pathname.split('/')[1]) {
        case "recits":
          setPage("récits");
          break;
        case "barometre":
        case "barometres":
          setPage("");
          break;
        case "activite":
        case "exploration":
          setPage("activite");
          break;
        case "experimentation":
          setPage("experimentation");
          break;
        default:
          setPage("")
      }
    }, [location]);
  }

  const handleContact = () => {
    document.location.href = `mailto:${email}`;
  }

  useUpdateHeader();
  return (
    <>
      <Navbar expand="lg" className="header"  style={{background: headerColor}} sticky="top" expanded={navExpanded} onToggle={() => setNavExpanded(!navExpanded)}>
       <Container>
        <NavbarBrand>
          <Link to={state.user? "/tableau": "/"}>
            <img src={logo} alt={"logo"} className="header-logo"/>
          </Link>
        </NavbarBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse  id="basic-navbar-nav" className="justify-content-end">
          { state.user ?
          <Nav>
            <LinkContainer to="/tableau">
              <Nav.Link active={page === "tableau"} title={header_tableau}>{header_tableau}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/recits">
              <Nav.Link active={page === "recits"} title={header_stories}>{header_stories}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/barometre/nouveau">
              <Nav.Link active={page === "barometre"} title={header_barometer}>{header_barometer}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/compte">
              <Nav.Link>
                <img src={user} alt={"Mon compte"}/>
              </Nav.Link>
            </LinkContainer>


           
          </Nav>
          :
          <Nav>
           <LinkContainer to="/auth/compte/signup" className="text-center btn-second mx-4">
              <Nav.Link>
                Inscription
              </Nav.Link>
            </LinkContainer> 
            <LinkContainer className="btn-first text-white mx-4 text-center" to="/auth/compte/signin">
              <Nav.Link>
                Connexion
              </Nav.Link>
            </LinkContainer>
          </Nav>
        }
        </Navbar.Collapse>
        </Container>
      </Navbar>



      {fetchedUser &&
        <div className="site-content">

          <ScrollToTop smooth />

          <Switch>
            {window.scrollTo(0, 0)}

            <Route exact path="/">
              <Landing />
            </Route>

            <Route path="/auth/:section/:tab?">
              <Auth />
            </Route>

            <Route exact path="/recits">
              <Stories />
            </Route>
            <Route path="/recits/exploration/:activityId">
              <StoriesRelatedToActivities />
            </Route>
            <Route path="/recits/:storyId">
              <StoryDisplay />
            </Route>

            <Route path="/barometre/nouveau">
              {!state.user ? <Redirect to={"/auth/compte/signin"}/> : <BarometerNew /> }
            </Route>

            <Route path="/quizz/:dimensionId">
              {!state.user ? <Redirect to={"/auth/compte/signin"}/> : <Quizz /> }
            </Route>


            <Route path="/tableau">
              {!state.user ? <Redirect to={"/auth/compte/signin"}/> : <Dashboard /> }
            </Route>


            <Route path="/compte">
              {!state.user ? <Redirect to={"/tableau"} /> : <Account /> }
            </Route>
            <Route path="/recover">
              <ForgotPassword />
            </Route>
            <Route path="/reset/:token">
              <ResetPassword />
            </Route>
            <Route path="/confirm/:token">
              <ConfirmEmail />
            </Route>

            <Route path="/cgu">
              <Legal type={"cgu"} />
            </Route>
            <Route path="/pdc">
              <Legal type={"pdc"} />
            </Route>
            <Route path="/ml">
              <Legal type={"ml"} />
            </Route>

            <Route path="/">
              <Redirect to="/"/>
            </Route>
          </Switch>

          <UpdatedLegalModal />

        </div>
      }

      <footer className="footer">
      
      <Row className="justify-content-md-center align-items-center">
      <Col lg={1} className="my-2">
        <img src={logo} alt={"logo"} className="footer-logo"/>
      </Col>
      
      <Col lg={3} className="my-2">
        <Link to="/cgu">{cguLabel}</Link>
      </Col>
      <Col lg={3} className="my-2">  
        <Link to="/pdc">{pdcLabel}</Link>
      </Col>
      <Col lg={2} className="my-2">
        <Link to="/ml">{mlLabel}</Link>
      </Col>
      <Col lg={3} className="my-2">
        <button className="btn-first" onClick={handleContact}>{footer_contact}</button>
      </Col>
        </Row>
        <div/>
      </footer>
  </>
  );
}

export default hot(App);
