import React from "react";

export const landing_name = <>
  9 ¾ :<br/><grey>Reprendre la main sur son travail</grey>
</>;

export const landing_main = <>
  <p className="header-text">Retravailler après un arrêt alors que les capacités sont encore incertaines, ce n’est pas toujours retravailler de la même manière. L’expérience peut aiguiser le regard sur les contraintes mais aussi sur les ressources qui peuvent influencer le cours de la reprise. 
Ce site vous propose d’explorer les différents chemins possibles.</p>
  </>

export const landing_arrow_click = <>
  Dérouler pour voir la suite
</>
export const landing_asset_title = <>
  9 ¾ : une voie qui ne se trouve pas d’un coup de baguette magique
</>
export const landing_second_section_title = <>
  Faire un pas de côté et explorer trois chemins pour poursuivre son activité professionnelle.
</>

export const landing_asset_main = <>
  <p><b>Le « quai 9 ¾ »</b> est celui qu’emprunte le jeune Harry Potter pour se rendre à l’école des sorciers. <b>C’est un quai invisible pour les non-initiés,</b> qui n’apparaît qu’aux magiciens qui mobilisent leur sens de l’observation pour le trouver.</p>
  <p>
  Travailler avec un cancer ou une maladie chronique demande aussi d’aiguiser le regard pour tracer un chemin singulier. 
</p>
  <p>
  9 ¾ vous guide pour prendre du recul, pour considérer votre situation professionnelle avec vos propres yeux et vous aider à prendre part aux conditions de reprise de votre activité qui vous semblent les meilleures.
  </p>
</>
export const landing_approach_left_title = <>
  De quoi est faite votre activité professionnelle ?
</>

export const landing_approach_left_description = <>
  Faire un pas de côté et explorer d’autres chemins pour poursuivre son activité professionnelle.
</>

export const landing_approach_right = <>
  Ce site vous invite à :<br/>
  <br/>
  <ul>
    <li> <b>découvrir</b> des récits de salarié.e.s qui partagent l’expérience qu’elles et eux ont vécu pour articuler travail et santé.</li>
    <li><b> questionner</b> la façon dont votre activité s’organise pour nourrir votre réflexion sur d’autres façons de travailler.</li>
    <li><b>partir de votre propre expérience</b> pour bâtir de nouveaux équilibres et travailler en santé.</li>
  </ul>
</>

export const landing_stories_title = <>
  L’expérience des autres m’inspire
</>

export const landing_stories_description = <>
  <p><b>Les récits d’expérience</b></p><p>Découvrez les pratiques singulières élaborées par les salarié.e.s et les managers pour articuler travail et santé.</p>
</>

export const landing_barometer_title = <>
  Faire un point sur mon travail pour y voir plus clair
</>

export const landing_barometer_description = <>
<p><b>L’instantané</b></p><p>Faites une photographie de votre situation actuelle pour repérer les contraintes et les ressources de votre activité.</p>
</>

export const landing_activity_title = <>
  Explorer les contours de mon activité pour alléger, renforcer, adapter…
</>

export const landing_activity_description = <>
  <p><b>La loupe sur ma situation</b></p><p>Questionnez la façon dont votre activité s’organise pour nourrir votre réflexion sur d’autres façons de travailler.</p>
</>


export const landing_experimentation_title = <>
  Mon expérimentation
</>

export const landing_experimentation_description = <>
  <b>Articuler</b> de nouveaux équilibres dans mon travail
</>

export const landing_inscription = <>
  <h3 className="txt-dark">Créez votre espace personnel<br/>et commencez à tracer <br/>les contours de votre retour</h3><p className="p-3"><b>Compte sécurisé. Confidentialité des données garantie.</b></p>
</>

export const landing_register = <>
 Je créé mon espace
 </>

export const landing_what_its = <>
 <h2 className="mb-5">Gare aux faux-semblants...</h2><p className="mb-4">9 ¾… ce chiffre vous dit quelque chose ? <br/>
Issu de l’univers du conte de Harry Potter, le quai 9 ¾ est invisible pour les « moldus » (les non-magiciens). Il n’apparaît qu’aux yeux des sorciers qui cherchent, s’interrogent, regardent autour d’eux pour trouver comment se rendre sur le lieu de leurs études.</p><p>Travailler avec un cancer ou une maladie chronique demande aussi de tracer un chemin singulier. Le site 9 ¾ vous invite à prendre du recul pour vous questionner sur votre activité professionnelle, et identifier par vous-même les conditions d’une reprise réussie. </p>
 </>

 export const landing_its = <>
 <h3 className="text-center text-white pb-3">c'est :</h3>
 <ul className="text-white text-left mx-auto w-auto">
 <li>
 un espace de réflexion qui vous est personnel
 </li>
 <li>
 un outil pour mettre le travail au service de votre santé
 </li>
 <li>
 un guide dans vos réflexions, qui ne donne pas de réponses toutes faites
 </li>
 </ul>
 </>


 export const landing_its_not = <>
 <h3 className="text-center pb-3">ce n'est pas :</h3>
 <ul className="text-left mx-auto w-auto">
 <li>
 un forum de discussion entre malades
 </li>
 <li>
 un outil d’analyse de la maladie et ses effets
 </li>
 <li>
 un mode d’emploi des dispositifs de reprise existants
 </li>
 </ul>
 </>
 
 export const landing_road = <>
 <h2 className="mb-5">Vous allez trouver le chemin !</h2>
 <p className="mb-4">Au fur et à mesure qu’avance votre réflexion, les outils 9 ¾ pourront vous apparaître plus ou moins adaptés à votre contexte.  Prenez le temps de les tester et de vous les approprier, sans vous soucier outre mesure de “cocher toutes les cases”. </p>
 <p className="mb-4">La route est sinueuse, les chemins sont propres à chacun.  
 <br/>Rassurez-vous : vous allez trouver le vôtre… réfléchir, c’est déjà se mettre en mouvement.</p>
 </>
