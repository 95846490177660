import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ScrollTo } from "react-scroll-to";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Context} from "../../state/Store";
import {Link} from "react-router-dom";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/cjs/Modal";

import Phase from "./Phase";
import StoryBox from "./StoryBox";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import arrowWhite from "../../img/arrow-down-white.svg";
import storiesHeader from "../../img/stories_header.svg";

import consultedMin from "../../img/consulted.svg";
import heartMin from "../../img/heart_min.svg";
import notesMin from "../../img/notes_min.svg";
import pencilMin from "../../img/pencil_min.svg";
import bull from "../../img/bull.svg";
import bullOrange from "../../img/bull-orange.svg";

import {email} from "../../texts/_params";
import { Waiter, useWait } from "react-wait";

import {
  stories_explanation,
  stories_filter,
  stories_filter_footer,
  stories_intro,
  stories_left_description,
  stories_left_title,
  stories_categories_title,
} from "../../texts";
  const handleContact = () => {
    document.location.href = `mailto:${email}?subject=Mon partage d'expérience à l'équipe 9 3/4 - travail et santé`;
  }

const Stories = (props) => {
  const [show, setShow] = useState(false);
  const firstSectionRef = useRef(null);
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  const [state] = useContext(Context);

  const { data, loading } = state;
  const [phases, setPhases] = useState([]);
  const [categories, setCategories] = useState([]);
  const [highlights, setHighlights] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredStories, setFilteredStories] = useState([]);
  const [stories, setStories] = useState([]);
  const [consultedtest, setConsultedtest] = useState(null);
  const [tmpStories, setTmpStories] = useState([]);
  const [consultedStory, setConsultedStory] = useState([]);
  const [favoriteStory, setFavoriteStory] = useState([]);
  const [notesStory, setNotesStory] = useState([]);
  const [highlightStory, setHighlightStory] = useState([]);
  const [timeline, setTimeline] = useState(-1)
  const [width, setWidth]   = useState(window.innerWidth);
  const [timelineStories, setTimelineStories] = useState([])
  const [selectedTag, setSelectedTag] = useState([])
  const { dimension } = useParams();

  useEffect(() => {

  console.log("param");
    axios.get(
    '/categories'
    ).then((res) => {
      res.data.push({_id: 'all', title: 'tous'})
      setCategories(res.data);
    }).catch((e) => {
      console.log(e);
    });
  }, [])

  useEffect(() => {
    axios.get(
    '/phases'
    ).then((res) => {
      setPhases(res.data);
    }).catch((e) => {
      console.log(e);
    });
  }, []);


  useEffect(() => {
    if (state.user)
      axios.get(
      '/storyHighlights/get/'
      ).then((res) => {
        setHighlights(res.data);
      }).catch((e) => {
        console.log(e);
      });
  }, []);

function removeConsulted() {
  setConsultedtest(null);
}
useEffect(() => {
  if (!state.user)
  {
    console.log('pas co')
  }
    else
    {
  if (!consultedStory[0])
  if (state.user.consultedStories)
  state.user.consultedStories.forEach(id => {
  axios.get(
    '/story/get/' + id
    ).then((res) => {
      consultedStory.push(res.data);
    }).catch((e) => {
      console.log(e);
    });
  
})
if (state.user)
if (!favoriteStory[0])
  state.user.favoriteStories.forEach(id => {
  axios.get(
    '/story/get/' + id
    ).then((res) => {
      favoriteStory.push(res.data);
    }).catch((e) => {
      console.log(e);
    });
})
if (state.user)
if (!notesStory[0])
  axios.get(
    '/storynotes/'
    ).then((res) => {
      res.data.forEach(notes => {
        notesStory.push(notes.story);
      })
    }).catch((e) => {
      console.log(e);
    });
if (!highlightStory[0])
{
    axios.get(
    '/storyHighlights/user/'
    ).then((res) => {
      res.data.forEach(highlight => {
        highlightStory.push(highlight.story);
      })
    }).catch((e) => {
      console.log(e);
    });
  }
  if (!timelineStories[0])
  {
    axios.get(
    '/stories/'
    ).then((res) => {
        setTimelineStories(res.data);

    }).catch((e) => {
      console.log(e);
    });
  }

}
}, []);

function consulted () {
  setTimeline(0);
  setConsultedtest(1);
}

const favorite = () => {
  setTimeline(0);
  setConsultedtest(2)
}
const sort_timelime = (val) => {
    setConsultedtest(5);
    setTimeline(val);
  }

const notes_stories = () => {
  setTimeline(0);
    setConsultedtest(3)
}
const highlight_stories = () => {
  setTimeline(0);
    setConsultedtest(4)
}

  // filter by category
  useEffect(() => {
    if (selectedCategory) {
      let filteredStories = [[], [], []];
      phases.forEach(phase => {
        phase.stories.forEach(story => {
          let categoryRank = story.categories.map(c => c._id).indexOf(selectedCategory._id);
          if (categoryRank > -1) filteredStories[categoryRank].push(story);
        });
      });
      filteredStories = filteredStories.flat();
      setFilteredStories(filteredStories);
    }
  }, [selectedCategory]);


  return <>
  <div className="intro pb-5">
    <Container fluid={true}>
      <Row className="pb-5">
        <Col>
          <h1>{stories_left_title}</h1>
          {stories_left_description}
        </Col>
         <Col>
        <div className="intro-img">
          <img src={storiesHeader} alt="logo" className="intro-logo"/>
        </div>
        </Col>
      </Row>
    </Container>
  </div>
  { width > 1100 ?
    <>
  <div className="timeline-recit">

  <Container fluid={true}>
    <Row className="text-center d-flex flex-row">
    <Col>
    <Link onClick={()=>sort_timelime(1)}>
    { timeline == 1?
      <>
        <img src={bullOrange} alt="logo" className="intro-logo"/>
        <br/>
        <p className="text-saumon">À l’annonce de la maladie</p>
      </>
      :
      <>
      <img src={bull} alt="logo" className="intro-logo"/>
        <br/>
        À l’annonce de la maladie
        </>
      }
    </Link>
    </Col>

    <Col>
    <Link  onClick={()=>sort_timelime(2)}>
    { timeline == 2?
      <>
      <img src={bullOrange} alt="logo" className="intro-logo"/>
      <br/>
        <p className="text-saumon">Au début des soins</p>
        </>
      :
      <>
      <img src={bull} alt="logo" className="intro-logo"/>
      <br/>
        Au début des soins
        </>
      }
    </Link>
    </Col>

     <Col>
    <Link  onClick={()=>sort_timelime(3)}>
    { timeline == 3?
      <>
      <img src={bullOrange} alt="logo" className="intro-logo"/>
      <br/>
        <p className="text-saumon">Au moment du retour au travail</p>
        </>
        :
        <>
        <img src={bull} alt="logo" className="intro-logo"/>
        <br/>
        Au moment du retour au travail
        </>
      }
    </Link>
    </Col>

    <Col>
    <Link  onClick={()=>sort_timelime(4)}>
    { timeline == 4?
      <>
      <img src={bullOrange} alt="logo" className="intro-logo"/>
      <br/>
        <p className="text-saumon">Après le retour au travail</p>
        </>
        :
        <>
        <img src={bull} alt="logo" className="intro-logo"/>
        <br/>
        Après le retour au travail
        </>
      }
    </Link>
    </Col>
    </Row>
    </Container>
  </div>
  </>
  :
  <>
  </>
  }
  <Container fluid={true}>
  <Row>
  { width > 1100?
    <>
  <Col lg={3} className="stories-nav pt-4 text-left px-3">
<ScrollTo>
  {({scroll}) => (
<Link className="navgation-recit w-100 my-5" onClick={()=> {setConsultedtest(1);setTimeout(function(){scroll({y: 500, smooth: true});}, 100);}} id="lu">
  <Row>
    <Col  className={consultedtest==1?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
      <img src={consultedMin} alt="logo" className="mx-2"/>
      Derniers récits lus
    </Col>
  </Row>
</Link>
)}
</ScrollTo>
<ScrollTo>
  {({scroll}) => (
<Link className="navgation-recit w-100 my-5" onClick={() => {setConsultedtest(2);setTimeout(function(){scroll({y: 500, smooth: true});}, 100);}}>
  <Row>
    <Col  className={consultedtest==2?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
      <img src={heartMin} alt="logo" className="mx-2"/>
      Récits favoris
    </Col>
  </Row>
</Link>
)}
</ScrollTo>
<ScrollTo>
  {({scroll}) => (
<Link className="navgation-recit w-100 my-5" onClick={() => {setConsultedtest(3);setTimeout(function(){scroll({y: 500, smooth: true});}, 100);}}>
  <Row>
    <Col  className={consultedtest==3?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
      <img src={notesMin} alt="logo" className="mx-2"/>
      Récits annotés
    </Col>
  </Row>
</Link>
)}
</ScrollTo>
<ScrollTo>
  {({scroll}) => (
<Link className="navgation-recit w-100 my-5" onClick={() => {setConsultedtest(4);setTimeout(function(){scroll({y: 500, smooth: true});}, 100);}}>
<Row>
    <Col className={consultedtest==4?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
      <img src={pencilMin} alt="logo" className="mx-2"/>
      Récits surlignés
  </Col>
</Row>
</Link>
)}
</ScrollTo>
    <p className="mt-5">Vous souhaiteriez partager
votre expérience ?</p>
<button className="btn-second py-2" onClick={handleContact}>Envoyez-nous votre récit</button>
  </Col>
  </>
  :
  <>
  <Row className="mobile-nav justify-content-around w-100 mx-auto">
  <Col className={consultedtest==1?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"} id="lu">
  <Link className="navgation-recit my-5" onClick={consulted}>
      <img src={consultedMin} alt="logo" className="mx-2"/>
      <br/>
      Lus
</Link>
</Col>
<Col className={consultedtest==2?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
<Link className="navgation-recit my-5" onClick={favorite}>
      <img src={heartMin} alt="logo" className="mx-2"/>
      <br/>
      Favoris
</Link>
</Col>
<Col className={consultedtest==3?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
<Link className="navgation-recit my-5" onClick={notes_stories}>
      <img src={notesMin} alt="logo" className="mx-2"/>
      <br/>
      Annotés
</Link>
</Col>
<Col className={consultedtest==4?"my-2 nav-highlight selected-bg":"my-2 nav-highlight"}>
<Link className="navgation-recit my-5" onClick={highlight_stories}>
      <img src={pencilMin} alt="logo" className="mx-2"/>
      <br/>
      Surlignés
</Link>
</Col>
</Row>
  </>
}
  <Col lg={9} className="text-left">
  <div className="stories-intro">
    <Container fluid={true}>
      <Row ref={firstSectionRef}>
      {!consultedtest?
        <>
        <Col className="story-categories">

          { width > 1100?
            <>
          <h2 className="mb-4">{stories_categories_title}</h2>
          <Row>
            {categories.map((c, i) =>
              <ScrollTo>
                {({scroll}) => (
                  <Col className={selectedCategory?._id == c._id?"stories-tags focus-tag": "stories-tags"}
                    tabIndex={0}
                    key={"category-" + i}
                    style={{textAlign: "center", cursor: "pointer"}}
                    onClick={() => {
                      if (c.title=='tous')
                        setSelectedCategory(null);
                      else
                      setSelectedCategory(c);
                      scroll({y: 600, smooth: true})
                    }}
                    onKeyDown={(e) => e.key === 'Enter' && setSelectedCategory(c)}>
                      {c.title}
                  </Col>)}
              </ScrollTo>
            )}
          </Row>

            </>
            :
            <>
            <div className="text-center">
            <button className="btn-first w-100 py-2" onClick={()=>setShow(true)}>Filtrer les résultats</button>
            </div>
            <Modal className="modal-tag-mobile" centered show={show} onHide={() => setShow(false)} backdrop="static" >
              <Row className="bg-white py-3">
            {categories.map((c, i) =>
              <ScrollTo>
                {({scroll}) => (
                  <Col lg={12}className="stories-tags-mobile w-100 text-left mb-3"
                    tabIndex={0}
                    key={"category-" + i}
                    onClick={() => {

                      setShow(false);
                      if (c.title=='tous')
                        setSelectedCategory(null);
                      else
                      setSelectedCategory(c);

                      scroll({y: 600, smooth: true})
                    }}
                    onKeyDown={(e) => e.key === 'Enter' && setSelectedCategory(c)}>
                      {c.title}
                  </Col>)}
              </ScrollTo>
            )}
          </Row>
            </Modal>
            </>
          }
        </Col>
        </>
        :

        <>
        <div className="link-back story-title row mt-5" onClick={removeConsulted}>
          <FontAwesomeIcon icon={faArrowLeft} size={"1x"} className="mt-1"/> <span className="ml-2 mt-0">Retourner à l'ensemble des récits</span>
        </div>
                                { consultedtest == 1?
                consultedStory.map((story, index) =>
                <>
                <Col lg={12} className="stories-box" key={"story-box-" + index}>
                  <StoryBox {...story} highlights={highlights}/>
                </Col>
                </>
                )
                :
                consultedtest == 2?
                favoriteStory.map((story, index) =>
                <>
            <Col lg={12} className="stories-box" key={"story-box-" + index}>
              <StoryBox {...story} highlights={highlights}/>
            </Col>
            </>
                )
                :
                consultedtest == 3?
                notesStory.map((story, index) =>
                <>
            <Col lg={12} className="stories-box" key={"story-box-" + index}>
              <StoryBox {...story} highlights={highlights}/>
            </Col>
            </>
                )
                :
                consultedtest == 4?
                highlightStory.map((story, index) =>
                <>
            <Col lg={12} className="stories-box" key={"story-box-" + index}>
              <StoryBox {...story} highlights={highlights}/>
            </Col>
            </>
                )
                :
                consultedtest == 5?
                timelineStories.map((story, index) => 

                <>
                <Col lg={12} className={story.number==timeline?"stories-box": "d-none"} key={"story-box-" + index}>
                  <StoryBox {...story} highlights={highlights}/>
                </Col>
                </>
                )
                         :
                <>

                </>
              }

                {consultedtest==1 && consultedStory && !consultedStory[0]?<><p className="py-4">Vous n'avez pas encore lu de récits. </p></>:<></>}
                {consultedtest==2 && favoriteStory && !favoriteStory[0]?<><p className="py-4">Vous n'avez pas encore mis de récits en favoris. </p></>:<></>}
                {consultedtest==3 && notesStory && !notesStory[0]?<><p className="py-4">Vous n'avez pas encore annoté de récits.  </p></>:<></>}
                {consultedtest==4 && highlightStory && !highlightStory[0]?<><p className="py-4">Vous n'avez pas encore surligné de récits. </p></>:<></>}
        </>
      }
      </Row>

      {selectedCategory
        ? <>
      <Row>
        <Col>
          <Row>

            {filteredStories.map((story, index) =>
            <Col lg={12} className="stories-box" key={"story-box-" + index}>
              <StoryBox {...story} highlights={highlights}/>
            </Col>
                )}

              </Row>
            </Col>
            </Row>
            <div className="link-back ml-3 mt-5" onClick={() => setSelectedCategory(null)}>
              <FontAwesomeIcon icon={faArrowLeft} size={"1x"}/> <span>{stories_filter_footer}</span>
            </div>

            <hr/>
          </>
          : <>
             {consultedtest?
              <>
              {stories.map((story, index) =>
            <Col lg={12} className="stories-box" key={"story-box-" + index}>
              <StoryBox {...story} highlights={highlights}/>
            </Col>
                )}

            <hr/>
                </>
              :
              <>
            {phases.map((phase, index) => <Phase key={"phase-" + index} highlights={highlights} {...phase}/>)}
            </>
          }
          </>
        }
      </Container>
    </div>
  </Col>
</Row>
</Container>
  </>
}

export default Stories;
