export const header_stories = "Récits";

export const header_barometer = "L’instantané";

export const header_tableau = "Tableau de bord";

export const header_activity = "Activité";

export const header_experimentation = "Expérimentation";

export const footer_contact = "Contactez nous!";

export const updatedCGU = <>
  <b>Votre consentement éclairé nous importe !</b><br/>

  Nos Conditions générales d'utilisation et notre Politique de confidentialité ont été mises à jour.<br/>

  Avant de continuer l'exploration de votre activité professionnelle, prenez un instant pour les parcourir, et nous
  faire part de votre acceptation.
</>
