import React from "react";

export const quizz_intro  = <>
	J’explore cette dimension plus en détail
</>

export const tableau_header = <>
<p>Bienvenue sur votre tableau de bord : personnalisé, il vous permet de suivre où vous en êtes dans votre exploration</p>
</>

export const dashboard_postits_describe = <>
  <h2 className="pb-2">Votre synthèse en 4 post-it</h2>
  <p>Consignez sur ces post-it les conclusions de vos réflexions, pour les garder immédiatement accessibles.</p>
</>

export const score_low_title = <>
	Tout reste à faire... et vous pouvez y arriver !
</>

export const empty_story = <>
	Sélectionnez les récits qui font écho à ce que vous vivez ou à ce que vous voudriez tester pour vous pour constituer une bibliothèque personnelle dans laquelle piocher au fil de vos réflexions.
</>
