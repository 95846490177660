import React, {useContext, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import axios from "axios";
import {Context} from "../../state/Store";
import {SET_USER} from "../../state/Reducer";
import Alert from "react-bootstrap/Alert";
import {TYPE_ACCOUNT} from "./Auth";
import {TYPE_EXPERIMENTATION} from "../Experimentation/ExperimentationCard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import connect_img from "../../img/login.svg";
const Login = (props) => {

  const [state, dispatch] = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);

  const handleSubmit = (event) => {
    axios.post('/auth/login', {
        username: email, password
    }).then((res) => {
      if (res?.data.user) {
        dispatch({type: SET_USER, payload: res.data.user});
      }
      else setErrors("Mot de passe incorrect.");
    }).catch((e) => {
      if (e.response?.status === 401)
        setErrors("Mot de passe incorrect.");
      else setErrors("Erreur serveur.")
    });
    event.preventDefault();
  }

  return <>
    {state.user ? <Redirect to={`/${props.from === TYPE_ACCOUNT ? TYPE_EXPERIMENTATION : props.from}`} /> :
      <>
        <div className="">
  <Container fluid={true}>
    <Row className="justify-center">
    <Col lg={6} className="my-auto"><div className="intro-img">
      <img src={connect_img} alt="logo" className="intro-logo"/>
    </div>
  </Col>
    <Col lg={6} className="auth-block px-3 align-self-end">
    <h2>Connectez-vous</h2>

        <p className="p1">Pas encore inscrit ? <Link to={"/auth/" + props.from + "/signup"}><u>Créez un compte</u></Link></p>
        
    {errors && <Alert variant={"danger"}>
      {errors}
    </Alert>}
    <form className="auth-form" onSubmit={handleSubmit}>
          <label>Email</label>
          <input required type={"email"} placeholder={"Email"} value={email} onChange={(event) => setEmail(event.target.value)} />
          <label>Mot de passe</label>
          <input required type={"password"} placeholder={"Mot de passe"}  value={password} onChange={(event) => setPassword(event.target.value)} />
          <br/><br/>

        <p className="p1">Vous avez oublié votre mot de passe ? <Link to={"/recover"}><u>Mot de passe oublié</u></Link></p>
          <Button type="submit" className="btn-first w-100">Connexion</Button>
        </form>
        <br/>

    </Col>
  </Row>
</Container>
</div>
      </>
    }
  </>
}

export default Login;
