import React, {useContext, useEffect, useState} from "react";
import { useLocation } from 'react-router-dom'
import axios from "axios";
import Modal from "react-bootstrap/cjs/Modal";
import Button from "react-bootstrap/cjs/Button";
import {Context} from "../state/Store";
import {cgu_agreement, pdc_agreement, updatedCGU} from "../texts";
import Alert from "react-bootstrap/Alert";
import {SET_USER} from "../state/Reducer";

const UpdatedLegalModal = () => {

  const location = useLocation();
  const [state, dispatch] = useContext(Context);
  const [show, setShow] = useState(false);
  const [cguAgreement, setCguAgreement] = useState(false);
  const [pdcAgreement, setPdcAgreement] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if ( location.pathname !== "/cgu" && location.pathname !== "/pdc" && location.pathname !== "/ml"
      && state.user
      && (!state.user?.acceptedCGU || !state.user?.acceptedPDC)) {
      setShow(true);
    }
  }, [state]);

  const submit = () => {
    if (cguAgreement && pdcAgreement) {
      axios.post("/legal").then((res) => {
        dispatch({type: SET_USER, payload: res.data});
        setShow(false);
      });
    }
    else setErrors("Veuillez cochez les cases ci-dessus")
  }

  return <Modal centered dialogClassName="updated-legal-modal" show={show} onHide={() => setShow(false)} backdrop="static">
    <Modal.Body className={"updated-legal-modal-body"}>
      {updatedCGU}
      <br/>
      <table>
        <tbody>
        <tr>
          <td><div><input type={"checkbox"} checked={cguAgreement} onChange={(e) => setCguAgreement(e.target.checked)}/></div></td>
          <td><label className="cgu-label">{cgu_agreement}</label></td>
        </tr>
        <tr>
          <td><div><input type={"checkbox"} checked={pdcAgreement} onChange={(e) => setPdcAgreement(e.target.checked)}/></div></td>
          <td><label  className="cgu-label">{pdc_agreement}</label></td>
        </tr>
        </tbody>
      </table>
      {errors && <Alert variant={"danger"}>
        {errors}
      </Alert>}
      <Button className="btn btn-blue" onClick={submit}>
        Valider
      </Button>
    </Modal.Body>
  </Modal>
}

export default UpdatedLegalModal;
