import React, {useRef,useState,useEffect,useContext} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ScrollTo } from "react-scroll-to";
import { LinkContainer } from 'react-router-bootstrap';
import ReactPlayer from 'react-player'
import {Context} from "../state/Store";

import logoBlue from "../img/logo_blue.svg";
import logoWhite from "../img/logo_white.svg";
import {ReactComponent as arrowDownWhite} from "../img/arrow-down-white.svg";
import arrowRightWhite from "../img/arrow-right-white.svg";
import arrowRightBlue from "../img/arrow-right-blue.svg";
import landingStories from "../img/landing_stories.svg";
import landingBarometer from "../img/landing_barometer.svg";
import landingActivities from "../img/landing_activities.svg";
import landingExperimentation from "../img/landing_experimentation.svg";
import game_card from "../img/game_card.svg";
import landing_header from "../img/landing_header.svg";
import logoBlackReverse from "../img/logo_black_reverse.svg";
import landing_road_img from "../img/landing_road_img.svg";
import { useHistory } from 'react-router-dom';

import {
  landing_activity_description,
  landing_activity_title,
  landing_approach_left_description,
  landing_approach_left_title,
  landing_asset_title,
  landing_asset_main,
  landing_approach_right,
  landing_arrow_click,
  landing_barometer_description,
  landing_barometer_title,
  landing_experimentation_description,
  landing_experimentation_title,
  landing_main,
  landing_name,
  landing_stories_description,
  landing_stories_title,
  landing_second_section_title,
  landing_inscription,
  landing_register,
  landing_what_its,
  landing_its,
  landing_its_not,
  landing_road,
} from "../texts";


const Landing = () => {
  const firstSectionRef = useRef(null);
  const [state] = useContext(Context);

if (state.user)
{
  window.location.href = "/tableau";
}
  return <>
    <div className="intro">
      <Container fluid={true}>
        <Row>
          <Col>
            <h1 className="txt-dark">{landing_name}</h1>
            {landing_main}
          </Col>
        <div className="intro-img">
          <img src={landing_header} alt="logo" className="intro-logo"/>
        </div>
        </Row>
      </Container>
    </div>

    {/*<div className="block-transition-two">
    <Container fluid={true}>
      <Row ref={firstSectionRef}>
        <Col>
          <h2 className="subtitle txt-dark">{landing_approach_left_title}</h2>
          <div className="player-wrapper">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            className="react-player"
            playing
            width="100%"
            height="100%"
            controls={false}
            muted={true}
          />
          </div>
        </Col>
      </Row>
      </Container>
      </div>*/}
      <div className="block-transition-two">
      <Container className="" fluid={true}>
        <Row className="background-border-grey col-white">
          <Col lg={7}>
            <h2 className="subtitle color-grey">{landing_asset_title}</h2>
            {landing_asset_main}
          </Col>

          <Col lg={5} className="game-card">
          <img src={game_card}/>
          </Col>
        </Row>
        </Container>
        </div>
      <div className="block-transition-two">
      <Container className="" fluid={true}>
         <Col lg={12}>
        <h2 className="subtitle text-dark">{landing_second_section_title}</h2>
        </Col>
        <Row className="block-row" >

        <LinkContainer style={{minWidth: 300}} to={"/barometre"}>
          <Col lg={4}>
          <div className="mx-2 border-background-col">
          <img src={landingBarometer} />
          <h3 className="color-grey">{landing_barometer_title}</h3>
          {landing_barometer_description}
          </div>
        </Col>
        </LinkContainer>
        <LinkContainer style={{minWidth: 300}} to={"/recits"}>

        <Col lg={4}>
        <div className="mx-2 border-background-col">

        <img src={landingStories} />
        <h3 className="color-grey">{landing_stories_title}</h3>
        {landing_stories_description}
        <button className="btn-first">Lire des récits</button>
        </div>
        </Col>
        </LinkContainer>
        <LinkContainer style={{minWidth: 300}} to={"/activite"}>

        <Col lg={4}>
        <div className="mx-2 border-background-col">
        <img src={landingActivities} />
        <h3 className="color-grey">{landing_activity_title}</h3>
        {landing_activity_description}
        </div>
        </Col>
        </LinkContainer>
        </Row>
      </Container>
      </div>
      {!state.user?
        <>
      <div className="block-transition">
      <Container fluid={true}>
        <Row className="row-center">
          <Col lg={12} className="txt-dark">
            {landing_inscription}
            <button to={"/auth/compte/signin"} className="btn-first">{landing_register}</button>
          </Col>
        </Row>
      </Container>
      </div>
      </>
      :
      <>
      </>
    }
      <div className="block-transition-two">
      <Container  className="" fluid={true}>
      
      <Col lg={12} className="mb-5 pb-5">
      {landing_what_its}
      </Col>
      <Row className="justify-content-center">
      <Col lg={5}  className="block-blue-2 mx-3">
      <img className="py-4" src={logoWhite}/>
      {landing_its}
      </Col>
      <Col lg={5} className="block-grey-2 mx-3">
      <img className="py-4" src={logoBlackReverse}/>
        {landing_its_not}
      </Col>
      </Row>
      </Container>
      </div>
      <div className="block-transition-two">
      <Container fluid={true}>
     </Container>
     </div>
  </>
}

export default Landing;

