import React, {useContext, useEffect, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import HighlightClusters from "./HighligtClusters";
import {Context} from "../../state/Store";

import Row from "react-bootstrap/Row";

import autonomie from "../../img/autonomie.svg";
import carriere from "../../img/carriere.svg";
import relationnel from "../../img/relationnel.svg";
import tmp from "../../img/tmp.svg";
import condition from "../../img/condition.svg";

const StoryBox = (props) => {

  const [state] = useContext(Context);
  const box = useRef(null);
  const [boxWidth, setBoxWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (box.current)
        setBoxWidth(box.current.offsetWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  function dimension(val) {
    if (val.title == "Autonomie")
      return (<div className="mr-3 pt-3 pb-1"><p className="dimension-story"><img src={autonomie} className="mb-1 mr-2"/>{val.title}</p></div>)
    else if (val.title == "Carrière")
      return (<div className="mr-3 pt-3 pb-1"><p className="dimension-story"><img src={carriere} className="mb-1 mr-2"/>{val.title}</p></div>)
    else if (val.title == "Temps de travail")
      return (<div className="mr-3 pt-3 pb-1"><p className="dimension-story"><img src={tmp} className="mb-1 mr-2"/>{val.title}</p></div>)
    else if (val.title == "Relationnel")
      return (<div className="mr-3 pt-3 pb-1"><p className="dimension-story"><img src={relationnel} className="mb-1 mr-2"/>{val.title}</p></div>)
    else if (val.title == "Conditions de travail")
      return (<div className="mr-3 pt-3 pb-1"><p className="dimension-story"><img src={condition} className="mb-1 mr-2"/>{val.title}</p></div>)

  }
  
  return <Link to={"/recits/" + props._id} className="story-box-link">
    <Col ref={box}>
      <div className="story-box-preview">
        <h3 className="p2 py-3"><b>{props.title}</b></h3>
        <p className="p1">{props.intro}</p>
          <Row className="d-flex flex-row-reverse text-right">
          {

          props.dimensions.map((val) => {
           return(dimension(val))
            
          })
        }
        </Row>
      </div>
      {/*
        BARRE DE PROGRESSION HIGHLIGHT
      {state.user && <HighlightClusters
        width={boxWidth}
        height={20}
        totalLength={props.testimony.toString().length}
        highlights={props.highlights?.filter(h => h.story._id === props._id)} /> }*/}
    </Col>
  </Link>
}

export default StoryBox;
