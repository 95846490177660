import React, {useContext, useEffect, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import {Context} from "../../state/Store";

const StoryBox = (props) => {

  const [state] = useContext(Context);
  const box = useRef(null);
  const [boxWidth, setBoxWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (box.current)
        setBoxWidth(box.current.offsetWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return <Link to={"/recits/" + props.id} className="story-box-link">
    <Col ref={box}>
      <div className="story-box-preview">

        <p className="phase-story">{props.phase}</p>
        <h3 className="p2 py-3">{props.title}</h3>
      </div>
      
    </Col>
  </Link>
}

export default StoryBox;
