export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const ADD_CONSULTED_STORY = 'ADD_CONSULTED_STORY';
export const ADD_FAVORITE_STORY = 'ADD_FAVORITE_STORY';
export const REMOVE_FAVORITE_STORY = 'REMOVE_FAVORITE_STORY';
export const ADD_CONSULTED_ACTIVITY = 'ADD_CONSULTED_ACTIVITY';
export const ADD_FAVORITE_ACTIVITY = 'ADD_FAVORITE_ACTIVITY';
export const REMOVE_FAVORITE_ACTIVITY = 'REMOVE_FAVORITE_ACTIVITY';
export const ADD_ANSWERED_ACTIVITIES = 'ADD_ANSWERED_ACTIVITIES';
export const ADD_ANALYZED_ACTIVITIES = 'ADD_ANALYZED_ACTIVITIES';
export const REMOVE_ANSWERED_ACTIVITIES = 'REMOVE_ANSWERED_ACTIVITIES';
export const REMOVE_ANALYZED_ACTIVITIES = 'REMOVE_ANALYZED_ACTIVITIES';


const Reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case UNSET_USER:
      return {
        ...state,
        user: null
      };
    case ADD_CONSULTED_STORY:
      return {
        ...state,
        user: {
          ...state.user,
          consultedStories: [...state.user.consultedStories, action.payload]
        }
      }
    case ADD_FAVORITE_STORY:
      return {
        ...state,
        user: {
          ...state.user,
          favoriteStories: [...state.user.favoriteStories, action.payload]
        }
      }
    case REMOVE_FAVORITE_STORY:
      return {
        ...state,
        user: {
          ...state.user,
          favoriteStories: [...state.user.favoriteStories.filter(fs => fs !== action.payload)]
        }
      }
    case ADD_CONSULTED_ACTIVITY:
      return {
        ...state,
        user: {
          ...state.user,
          consultedActivities: [...state.user.consultedActivities, action.payload]
        }
      }
    case ADD_FAVORITE_ACTIVITY:
      return {
        ...state,
        user: {
          ...state.user,
          favoriteActivities: [...state.user.favoriteActivities, action.payload]
        }
      }
    case REMOVE_FAVORITE_ACTIVITY:
      return {
        ...state,
        user: {
          ...state.user,
          favoriteActivities: [...state.user.favoriteActivities.filter(fs => fs !== action.payload)]
        }
      }
    case ADD_ANSWERED_ACTIVITIES:
      return {
        ...state,
        user: {
          ...state.user,
          answeredActivities: [...state.user.answeredActivities, action.payload]
        }
      }
    case ADD_ANALYZED_ACTIVITIES:
      return {
        ...state,
        user: {
          ...state.user,
          analyzedActivities: [...state.user.analyzedActivities, action.payload]
        }
      }
    case REMOVE_ANSWERED_ACTIVITIES:
      return {
        ...state,
        user: {
          ...state.user,
          answeredActivities: [...state.user.answeredActivities.filter(a => a !== action.payload)]
        }
      }
    case REMOVE_ANALYZED_ACTIVITIES:
      return {
        ...state,
        user: {
          ...state.user,
          analyzedActivities: [...state.user.analyzedActivities.filter(a => a !== action.payload)]
        }
      }
    default:
      return state;
  }
};

export default Reducer;
