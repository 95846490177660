import React, {useRef,useState,useEffect,useContext} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import {ScrollTo} from "react-scroll-to";
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";

import { Tooltip, Button } from "@blueprintjs/core";

import {Link} from 'react-router-dom';


import Modal from "react-bootstrap/cjs/Modal";
import ReactHTMLParser from 'react-html-parser';
import {Context} from "../../state/Store";
import info from "../../img/info-bulle.svg";
import autonomie from "../../img/autonomie.svg";
import conditions from "../../img/condition.svg";
import temps from "../../img/tmp.svg";
import carriere from "../../img/carriere.svg";
import relationnel from "../../img/relationnel.svg";

import autonomieActive from "../../img/autonomie-active.svg";
import conditionsActive from "../../img/condition-active.svg";
import tempsActive from "../../img/tmp-active.svg";
import carriereActive from "../../img/carriere-active.svg";
import relationnelActive from "../../img/relationnel-active.svg";


import trash from "../../img/trash.svg";
import arrow from "../../img/arrow-down-blue.svg";
import {
  quizz_intro,
  quizz_left_description,
  quizz_left_title,
  quizz_left_description_after,
  quizz_left_intro_after,
  quizz_start,
  quizz_skip,
  quizz_button,
  quizz_result_submit,
  infoPostitOne,
  infoPostitTwo,
  tableau_header,
  quizz_postits_describe,
  score_low_title,
  quizz_finished
} from "../../texts";
import 'rc-slider/assets/index.css';
import axios from 'axios';
import quizzHeader from "../../img/quizz-header.svg";
import quizzHeaderAfter from "../../img/quizz-header-after.svg";
import quizzfinished from "../../img/barometerfinished.svg";
import storyImg from "../../img/storyquizz.svg";
import queryString from 'query-string'

const Quizz = () => {
  
  const { dimensionId } = useParams();
  const[dimension, setDimension] = useState([]);
  const [length, setLength] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState(['Me convient','Ne me convient pas', 'Ne me concerne pas']);
  const [answers,setAnswers] = useState([]);

  const [postitnotes,setPostitNotes] = useState('');
  const [postitnotestwo,setPostitNotesTwo] = useState('');
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [none, setNone] = useState(null);
  const [agree, setAgree] = useState(null);
  const [questionsTwo, setQuestionsTwo] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [index,setIndex] = useState(0);
  const [testing, setTesting] = useState (false);
  const [finished, setFinished] = useState (false);
  const [scores, setScores] = useState(null);
  const history = useHistory();
  const [tmp, setTmp] = useState([]);
  const [questionMap,setQuestionMap] = useState(()=>new Map());
  const [allDimensions, setAllDimensions] = useState([]);
  const [displayScore, setDisplayScore] = useState(null);
  const formats = ["bold","underline","italic","header","size"];

  const modules= {
    toolbar: [
      ["bold","underline","italic"],
      [{ 'size': [false, 'large'] }]
    ]
  };

 
const linkStory = "/recits/exploration/"+dimensionId

useEffect(()=>{
    axios.get('/dimensions/').then((res) =>{
      res.data.forEach((e) => {
        setLength(res.data.length)
        setAllDimensions(res.data);
        if (e._id == dimensionId)
          setDimension(e.title);
      })
    })
    .finally(() => {});
  },[]);
useEffect(()=>{
    let tmp = 0;
    axios.get('/quizzs/dimension/' + {dimensionId},
      ).then((res) =>{
        console.log("dimension =-", dimensionId);
        console.log("res.date = ", res.data);
      (res.data.length>0) ?
      res.data.forEach((e) => {
        if (e.dimensions == dimensionId) {
        setQuestions(prevState=>[...prevState,e]);
        setQuestionMap(new Map(questionMap.set(e._id, {title:e.question})))
        tmp =tmp + 1
        setLength(tmp);
      }
      })
      :
      console.log("do nothing")
    })
    .finally(() => {
      if (!answers[0])
      {
      axios.get(
      '/quizzuser/score/'+ {dimensionId},
    ).then((res) => {
      if (res.data[0]) {
        res.data[0].answers.forEach((a, i) => {
          if (a.dimension == dimensionId)
          {
            answers.push(a);
          }
        });
        if (answers.length > 0)
          setFinished(true);
        
      } else {
        console.log("Data is empty");
      }
    }).catch((e) => {
      console.log(e);
    })
  }
    });
  },[]);

useEffect(()=> {

     },[loading]);

  useEffect(() => {
    axios.get('/postits/').then((res) => {
        (res.data.length>0) ? 
        res.data.forEach((e) => {
          if (e.category==='one') {
            setPostitNotes(e.notes);
          //  console.log(true);
          } else if (e.category==='two') {
            setPostitNotesTwo(e.notes);
          //  console.log('two');
          } 
        //  console.log(e);
        })
        : updateAll();
    })
  },[]);
  function get_question(val) {
    axios.get(
      '/question/id/'+ val
      ).then((res) => {
      console.log("get question",res);
       return (res.data.question)
    }).catch((e) => {
      console.log("error",e);
    })

  }

  const updateAll = () => {
    updateCategory();
    updateCategoryTwo();
  }
    const updateCategory = () => {
    axios.post('/postits/save',{user:"new",category:"one",notes:"hello1"})
    .then((res) => {
     // console.log(res);
    }).catch((e) => {
     // console.log(e);
    })
   
  }

  const updateCategoryTwo = () => {
    axios.post('/postits/save',{user:"new",category:"two",notes:"hello2"})
    .then((res) => {
     // console.log(res);
    }).catch((e) => {
    //  console.log(e);
    })
  }
const updateNotes = () => {
    axios.put(
      '/postits/update',{user:"hello",category:"one",notes:postitnotes}
    ).then((res) => {
     // console.log(res);
    }).catch((e) => {
     // console.log(e);
    })
  }

  const updateNotesTwo = () => {
    axios.put(
      '/postits/update',{category:"two",notes:postitnotestwo}
    ).then((res) => {
     // console.log(res);
    }).catch((e) => {
     // console.log(e);
    })
  }
  const change_answer = (score, answer, i) => {
    answer.score = score;
    answers[i] = answer;
    setAgree(null);
    setNone(null);
    updateScores();
  }

  const updateScores = () => {
    axios.put(
      '/quizzuser/score',{answers}
    ).then((res) => {
      console.log("save score");
    }).catch((e) => {
      console.log("save score error",e);
    })
  }


  useEffect(()=>{
    if (scores !== null) {
      updateScores();
    } else {
      console.log("Can't log null values");
    }
  },[scores])

  const addAnswer = (index, id,v,c) => {
      answers[index] = {score:v, id: id, dimension: c}
      updateScores();
  }


  const renderOptions = () => {
    let options = [];
    return (
      <div>Hello</div>
    );
  }

  if (loading) {
    return <p>Data is loading...</p>;
  }
  const reload = () => {
    setFinished(null);
    setAnswers([]);
    setIndex(0);
  }

  function dim_img(val) {
    console.log(val);
    if (val == 'Conditions de travail')
      return (<img className="mr-1" src={conditions} />)
    else if (val == 'Autonomie')
      return (<img className="mr-1" src={autonomie} />)
    else if (val == 'Relationnel')
      return (<img className="mr-1" src={relationnel} />)
    else if (val == 'Carrière')
      return (<img className="mr-1" src={carriere} />)
    else if (val == 'Temps de travail')
      return (<img className="mr-1" src={temps} />)
  }
  function dim_img_ok(val) {
    console.log(val);
    if (val == 'Conditions de travail')
      return (<img src={conditionsActive} className="fill-dim mr-1" />)
    else if (val == 'Autonomie')
      return (<img src={autonomieActive} className="fill-dim mr-1" />)
    else if (val == 'Relationnel')
      return (<img src={relationnelActive} className="fill-dim mr-1" />)
    else if (val == 'Carrière')
      return (<img src={carriereActive} className="fill-dim mr-1" />)
    else if (val == 'Temps de travail')
      return (<img src={tempsActive} className="fill-dim mr-1" />)
  }

  return (
      <div>
    <div className="intro pb-5" id="header">

    <Container>
    <Row className="pb-5 justify-content-between">
    <Col lg={6}>
      <h1>{quizz_left_title}<br/>{dimension}</h1>
      {!finished?
        <>
      <p>{quizz_left_description}</p>
      <p>{quizz_left_intro_after}</p>
      <ScrollTo>
      {({scroll}) => (
      <button className="btn-first px-5 py-3 mt-3" onClick={()=>{reload();scroll({y: 800, smooth: true})}}>{quizz_button}</button>
      )}
      </ScrollTo>
      </>
      :
      <>
      <p>{quizz_left_description_after}</p>
      <p>{quizz_left_intro_after}</p>
      </>
    }
      
    </Col>
    <Col lg={5} className="intro-img my-auto">
    {!finished?
        <>
      <img src={quizzHeader} alt="logo" className="intro-logo mx-auto"/>
      </>
      :
      <>
      <img src={quizzHeaderAfter} alt="logo" className="intro-logo mx-auto"/>
      </>
    }
    </Col>
    </Row>

  </Container>
    </div>
    <div className="intro-dashboard pb-5 pt-5">
    
    <Container>
              <div className="quizz-box-preview-main p-5">
                {!finished ?
                  <>
                  <Row className="d-flex justify-content-center">
                  <Col lg={10}>
                  <Row className="d-flex justify-content-left">
                    <p className="text-saumon">Dimension :</p>
                    
                    {allDimensions.map((dim) => {
                      if (dim.title == dimension){

                        return (<p className="dimension-highlight dimensions-title"> {dim_img_ok(dim.title)}{dimension}</p>)
                      }
                      else{
                        return(<p className="disable-dimension dimensions-title"> {dim_img(dim.title)}{dim.title}</p>)
                      }
                    })}
                    </Row>
                    </Col>
                    
                  <Col lg={10}>
                  <div className="question-quizz mb-">
                  <h3 className="p2 pb-3">{questions[index]?.question}</h3>
                  <Row className="d-flex justify-content-between mb-0 pb-0">
                    <h5 onClick={()=>{if (index>=1) {setIndex(old=>old-1)}}}>Question précédente</h5>
                    <h4 className="txt-orange">{index+1}/{length}</h4>
                  </Row>
                  </div>
                  </Col>
                  </Row>
                  <Row>
                  {options.map((op,i) => {  
                      if (answers[index]?.score === Number(i)) {
                        return (
                          <Col onClick={()=>{if (index<length) {setIndex(old=>old+1)};addAnswer(index, questions[index]?._id,i,dimensionId);if (index===length-1) {setFinished(true)}}}><button className="btn btn-first w-100">{op}</button></Col>
                        )
                      } else {
                        return (
                          <Col onClick={()=>{if (index<length) {setIndex(old=>old+1)};addAnswer(index, questions[index]?._id,i,dimensionId);if (index===length-1) {setFinished(true)}}}><button className="btn btn-first w-100">{op}</button></Col>  
                        )
                      }
                    }
                  )}
                  </Row>
                   
                  </>
                :
                  <>
                  <Container>
                    <Row className="d-flex justify-content-center answer-quizz">
                    <Col lg={12} className="text-center mx-auto">
                        <ScrollTo>
                          {({scroll}) => (
                          <button className="btn-first px-5 py-1 mb-3" onClick={()=>{reload();scroll({y: 800, smooth: true})}}>Mettre à jour mon analyse</button>
                          )}
                        </ScrollTo>
                          <p>vos résultats actuels seront supprimés</p>
                    </Col>
                    <Col lg={6} className="text-center">
                      <h2>Me convient</h2>
                      { answers.map((val, i) => {
                        if (val.score === 0){
                          if (!agree)
                            setAgree(true);
                          return(
                            <ScrollTo>
                            {({scroll}) => (
                            <div className="card-quizz my-4">
                             <h4 className="text-left">{questionMap.get(val.id)?.title}</h4>
                            <Row className="d-flex justify-content-between mt-5">

                            <div className=" px-1 disable">{options[0]}</div>
                            <Link className=" px-1 option-btn-after" onClick={() =>{change_answer(1, val, i);setTimeout(function(){scroll({y: 600, smooth: true});}, 100);}}>{options[1]}</Link>
                          
                            <Link className=" px-1 option-btn-after" onClick={() => {change_answer(2, val, i);
                            setTimeout(function(){scroll({y: 600, smooth: true});}, 100);}}><img src={trash} /></Link>
  
                            </Row>
                            </div>
                            )}
                            </ScrollTo>
                            )                    
                      }
                      }
                        )}
                        {!agree?
                              <>
                              <p>
                            Vous n'avez pas identifié de situations faisant écho à la vôtre. Passez directement à la synthèse.
                            </p>
                            </> 
                            :
                            <>
                            </>
                          }
                    </Col>
                    <Col lg={6} className="text-center">
                      <h2>Ne me convient pas</h2>
                      
                      { answers.map((val, i) => {
                        if (val.score === 1){
                          if (!none)
                            setNone(true);
                          return(
                            <ScrollTo>
                          {({scroll}) => (
                            <div className="card-quizz my-4">

                            <h4 className="text-left">{questionMap.get(val.id)?.title}</h4>
                            <Row className="d-flex justify-content-between mt-5">
                            <Link className=" px-1 option-btn-after" onClick={() =>{
                              change_answer(0, val, i);
                            setTimeout(function(){scroll({y: 600, smooth: true});}, 100);}}>{options[0]}</Link>
                            <div className=" px-1 disable">{options[1]}</div>
                            <Link className=" px-1 option-btn-after" onClick={() => {change_answer(2, val, i);
                            setTimeout(function(){scroll({y: 600, smooth: true});}, 100);}}><img src={trash} /></Link>

                            </Row>
                            </div>
                            )}
                            </ScrollTo>
                            )   

                      }
                      }
                        )}
                        {!none?
                              <>
                              <p>
                            Vous n'avez pas identifié de situations faisant écho à la vôtre. Passez directement à la synthèse.
                            </p>
                            </> 
                            :
                            <>
                            </>
                          }
                    </Col>

                    </Row>
                    </Container>
                  </>
                }
                
              </div>
              </Container>
              <div className="block-transition-three">
              { finished?
                <>
        <Container>
        <Row>
          <div id="formulaire">
            <div className="intro-dashboard-postits pt-5">
              {quizz_postits_describe}
            </div>
            <Modal classname="postit-modal" className="w-100" centered show={show} onHide={() => setShow(false)} backdrop="static" >
              <div className="quill-container">
              <h3>À faire évoluer</h3>
                <ReactQuill
                  defaultValue={postitnotes}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotes}
                  style={{minHeight: '300px'}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShow(false)}>Annuler</button>
                <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotes();setShow(false)}}>Enregistrer</button>
                </Row>
                </div>
            </Modal>
            <Modal classname="postit-modal" centered show={showTwo} onHide={() => setShowTwo(false)} backdrop="static" >
              <div className="quill-container">
              <h3>À préserver</h3>
                <ReactQuill
                  defaultValue={postitnotestwo}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotesTwo}
                  style={{minHeight: '300px'}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                  <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShowTwo(false)}>Annuler</button>
                  <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotesTwo();setShowTwo(false)}}>Enregistrer</button>
                </Row>
              </div>
            </Modal>
            
            
            <Container>
              <button onClick={updateCategory}></button>
              <Row className="barometer-question">
            
                    <Row className="d-flex justify-content-end w-100">
                      <Col lg={6}>
                      <p className="title-postit">À faire évoluer <Tooltip content={infoPostitOne} position='right'><img src={info} alt="info"/></Tooltip></p>
                      
                      <div id="categoryone" className="postit" onClick={()=>setShow(true)}>
                        {ReactHTMLParser(postitnotes)}
                      </div>
                      </Col>
                      <Col lg={6}>
                      <p className="title-postit">À préserver <Tooltip content={infoPostitTwo} position='right'><img src={info} alt="info"/></Tooltip></p>
                      
                      <div id="categorytwo" className="postit" onClick={()=>setShowTwo(true)}>{ReactHTMLParser(postitnotestwo)}</div>
                      </Col>
                    </Row>
              </Row>
              <Row className="d-flex justify-content-center text-left">
              <Col lg={12}>
                <h2>Prolongez la réflexion...</h2>
                </Col>
                <Col lg={6}>
                <p>D’autres sont passés par là avant vous... Découvrez leurs difficultés, et comment ils ont tenté  de les surmonter pour influer positivement sur cette dimension de leur quotidien professionnel.</p>
                <a href={linkStory} className="btn btn-primary p-3 pr-5 pl-5 mb-5">Lire leurs récits</a>
                </Col>
                <Col lg={5}>
                <img src={storyImg} alt="logo" className="intro-logo"/>
                </Col> 
              </Row>
            </Container>
            
          </div>
          </Row>
        </Container>
        </>
            :
            <>
            </>
          }
        </div>
      </div>
      </div>
  );
}

export default Quizz;
