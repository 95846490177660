import React from 'react';

const AutonomieFigure = ({fill}) => {
    return (
        <svg width="191" height="179" viewBox="0 0 191 179" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M140.852 135.828L44.8653 160.594C41.6093 161.43 38.1553 160.938 35.2609 159.226C32.3666 157.514 30.2683 154.721 29.4264 151.461L0.397459 38.5103C-0.437073 35.2479 0.0540022 31.787 1.76296 28.887C3.47191 25.9869 6.25918 23.8845 9.51326 23.041L98.8133 0L121.67 10.2523L149.968 120.359C150.802 123.621 150.311 127.082 148.602 129.982C146.893 132.882 144.106 134.984 140.852 135.828Z" fill="#F59041"/>
    <path d="M10.4233 26.5824C8.10598 27.1831 6.1211 28.6802 4.90412 30.7454C3.68714 32.8106 3.33743 35.2752 3.93172 37.5984L32.9606 150.549C33.5602 152.87 35.0544 154.859 37.1155 156.079C39.1767 157.298 41.6364 157.648 43.955 157.053L139.942 132.287C142.259 131.686 144.244 130.189 145.461 128.124C146.678 126.058 147.028 123.594 146.433 121.271L118.574 12.8703L98.487 3.86035L10.4233 26.5824Z" fill="#F59041"/>
    <path d="M121.426 10.8349L106.571 14.6678C105.486 14.9476 104.335 14.7843 103.371 14.2139C102.407 13.6434 101.708 12.7124 101.429 11.6258L98.6033 0.632358C98.5909 0.584229 98.5923 0.533581 98.6074 0.486222C98.6224 0.438864 98.6505 0.396714 98.6884 0.364603C98.7262 0.332492 98.7724 0.311722 98.8215 0.304674C98.8706 0.297627 98.9206 0.304586 98.966 0.324756L121.467 10.3372C121.517 10.3595 121.559 10.3968 121.587 10.444C121.615 10.4913 121.628 10.546 121.623 10.6007C121.619 10.6555 121.597 10.7074 121.562 10.7494C121.526 10.7913 121.479 10.8212 121.426 10.8349Z" fill="#EF8533"/>
    <path d="M113.611 107.292L69.9092 118.568C69.6407 118.637 69.3612 118.653 69.0867 118.614C68.8122 118.575 68.548 118.482 68.3093 118.341C68.0705 118.2 67.8619 118.013 67.6953 117.791C67.5287 117.569 67.4073 117.316 67.3382 117.047C67.269 116.778 67.2535 116.498 67.2923 116.223C67.3312 115.948 67.4238 115.683 67.5647 115.444C67.7057 115.205 67.8923 114.996 68.1139 114.829C68.3355 114.662 68.5877 114.54 68.8562 114.471L112.558 103.195C112.826 103.126 113.106 103.11 113.38 103.149C113.655 103.188 113.919 103.281 114.158 103.422C114.396 103.563 114.605 103.75 114.772 103.972C114.938 104.194 115.06 104.447 115.129 104.716C115.198 104.985 115.213 105.265 115.174 105.54C115.136 105.815 115.043 106.08 114.902 106.319C114.761 106.558 114.575 106.767 114.353 106.934C114.131 107.101 113.879 107.223 113.611 107.292Z" fill="#CCCCCC"/>
    <path d="M123.821 112.03L71.6863 125.482C71.144 125.622 70.5685 125.54 70.0864 125.255C69.6042 124.969 69.2549 124.504 69.1153 123.961C68.9757 123.417 69.0572 122.841 69.3418 122.358C69.6265 121.875 70.0911 121.524 70.6333 121.385L122.768 107.933C123.31 107.793 123.885 107.875 124.367 108.161C124.849 108.446 125.198 108.911 125.338 109.454C125.477 109.997 125.396 110.574 125.111 111.057C124.827 111.539 124.363 111.89 123.821 112.03Z" fill="#CCCCCC"/>
    <path d="M52.0728 132.148C56.1939 132.148 59.5348 128.801 59.5348 124.672C59.5348 120.542 56.1939 117.195 52.0728 117.195C47.9517 117.195 44.6108 120.542 44.6108 124.672C44.6108 128.801 47.9517 132.148 52.0728 132.148Z" fill="white"/>
    <path d="M108.964 87.7253L50.9241 102.695C49.2467 103.126 47.4674 102.872 45.9764 101.99C44.4855 101.108 43.4047 99.6688 42.9711 97.9891L30.8081 50.6458C30.3784 48.9651 30.6315 47.1823 31.512 45.6884C32.3925 44.1945 33.8285 43.1116 35.5049 42.6772L93.5447 27.7075C95.2221 27.2768 97.0014 27.5305 98.4924 28.4127C99.9834 29.295 101.064 30.7337 101.498 32.4134L113.661 79.7567C114.09 81.4374 113.837 83.2202 112.957 84.7141C112.076 86.208 110.64 87.2909 108.964 87.7253Z" fill="white"/>
    <path d="M108.964 87.7253L50.9241 102.695C49.2467 103.126 47.4674 102.872 45.9764 101.99C44.4855 101.108 43.4047 99.6688 42.9711 97.9891L30.8081 50.6458C30.3784 48.9651 30.6315 47.1823 31.512 45.6884C32.3925 44.1945 33.8285 43.1116 35.5049 42.6772L93.5447 27.7075C95.2221 27.2768 97.0014 27.5305 98.4924 28.4127C99.9834 29.295 101.064 30.7337 101.498 32.4134L113.661 79.7567C114.09 81.4374 113.837 83.2202 112.957 84.7141C112.076 86.208 110.64 87.2909 108.964 87.7253ZM35.6964 43.4228C34.2173 43.8061 32.9502 44.7616 32.1733 46.0797C31.3964 47.3979 31.173 48.971 31.5522 50.4539L43.7152 97.7972C44.0978 99.2793 45.0514 100.549 46.367 101.327C47.6825 102.106 49.2525 102.329 50.7326 101.949L108.772 86.9798C110.252 86.5965 111.519 85.6409 112.296 84.3228C113.072 83.0047 113.296 81.4316 112.917 79.9486L100.754 32.6053C100.371 31.1233 99.4174 29.8538 98.1018 29.0753C96.7863 28.2969 95.2163 28.0731 93.7363 28.453L35.6964 43.4228Z" fill="#EF8533"/>
    <path d="M90.6003 43.6259L61.3084 51.1809C61.0523 51.2526 60.7786 51.2241 60.5427 51.1013C60.3067 50.9785 60.1261 50.7705 60.0373 50.5194C59.996 50.3882 59.9818 50.25 59.9956 50.1131C60.0093 49.9763 60.0508 49.8436 60.1174 49.7234C60.184 49.6031 60.2744 49.4977 60.383 49.4135C60.4916 49.3294 60.6162 49.2683 60.7492 49.234L90.5666 41.5435C91.771 42.155 91.4541 43.4057 90.6 43.626L90.6003 43.6259Z" fill="#EF8533"/>
    <path d="M91.9228 48.7741L62.6309 56.3291C62.3748 56.4008 62.1011 56.3723 61.8652 56.2495C61.6292 56.1267 61.4486 55.9187 61.3598 55.6676C61.3185 55.5364 61.3043 55.3982 61.3181 55.2613C61.3319 55.1245 61.3733 54.9918 61.4399 54.8716C61.5065 54.7513 61.5969 54.6458 61.7055 54.5617C61.8141 54.4776 61.9387 54.4165 62.0717 54.3822L91.8891 46.6917C93.0936 47.3031 92.7766 48.5539 91.9225 48.7742L91.9228 48.7741Z" fill="#EF8533"/>
    <path d="M57.0517 60.8935L48.1121 63.1993C47.8201 63.2742 47.5104 63.2301 47.2509 63.0765C46.9914 62.923 46.8032 62.6725 46.7278 62.3801L44.0122 51.8099C43.9374 51.5174 43.9814 51.207 44.1347 50.947C44.2879 50.687 44.5379 50.4985 44.8297 50.4229L53.7693 48.1172C54.0613 48.0423 54.371 48.0865 54.6305 48.24C54.89 48.3936 55.0781 48.644 55.1536 48.9363L57.8692 59.5065C57.9441 59.7991 57.9 60.1094 57.7468 60.3694C57.5935 60.6295 57.3435 60.818 57.0517 60.8935V60.8935Z" fill="#EF8533"/>
    <path d="M94.5457 59.3369L49.8408 70.8673C49.5848 70.9389 49.3111 70.9105 49.0751 70.7877C48.8392 70.6649 48.6586 70.4569 48.5698 70.2058C48.5285 70.0746 48.5143 69.9364 48.528 69.7995C48.5418 69.6626 48.5833 69.53 48.6499 69.4097C48.7165 69.2895 48.8069 69.184 48.9155 69.0999C49.0241 69.0158 49.1487 68.9547 49.2816 68.9204L94.5123 57.2544C95.7168 57.8659 95.3999 59.1166 94.5458 59.3369L94.5457 59.3369Z" fill="#EF8533"/>
    <path d="M95.8688 64.4863L51.1638 76.0167C50.9078 76.0884 50.6341 76.0599 50.3981 75.9371C50.1622 75.8143 49.9816 75.6063 49.8928 75.3552C49.8515 75.224 49.8373 75.0858 49.851 74.9489C49.8648 74.812 49.9063 74.6794 49.9729 74.5591C50.0395 74.4389 50.1299 74.3334 50.2385 74.2493C50.3471 74.1652 50.4717 74.1041 50.6046 74.0698L95.8353 62.4038C97.0398 63.0153 96.7229 64.266 95.8688 64.4863V64.4863Z" fill="#EF8533"/>
    <path d="M97.1908 69.6328L52.4859 81.1632C52.2298 81.2348 51.9561 81.2064 51.7201 81.0836C51.4842 80.9608 51.3036 80.7528 51.2148 80.5017C51.1735 80.3705 51.1593 80.2323 51.1731 80.0954C51.1868 79.9585 51.2283 79.8259 51.2949 79.7056C51.3615 79.5853 51.4519 79.4799 51.5605 79.3958C51.6691 79.3117 51.7937 79.2506 51.9266 79.2163L97.1574 67.5503C98.3618 68.1618 98.0449 69.4125 97.1908 69.6328Z" fill="#EF8533"/>
    <path d="M98.5138 74.782L53.8089 86.3124C53.5528 86.384 53.2791 86.3555 53.0432 86.2327C52.8072 86.1099 52.6266 85.902 52.5378 85.6508C52.4965 85.5197 52.4823 85.3814 52.4961 85.2446C52.5098 85.1077 52.5513 84.9751 52.6179 84.8548C52.6845 84.7345 52.7749 84.6291 52.8835 84.5449C52.9921 84.4608 53.1167 84.3997 53.2496 84.3655L98.4804 72.6995C99.6848 73.311 99.3679 74.5617 98.5138 74.782L98.5138 74.782Z" fill="#EF8533"/>
    <path d="M99.836 79.9285L55.1311 91.4589C54.875 91.5305 54.6014 91.502 54.3654 91.3792C54.1295 91.2564 53.9488 91.0484 53.8601 90.7973C53.8188 90.6661 53.8046 90.5279 53.8183 90.391C53.8321 90.2542 53.8735 90.1216 53.9402 90.0013C54.0068 89.881 54.0971 89.7756 54.2057 89.6914C54.3144 89.6073 54.4389 89.5462 54.5719 89.5119L99.8026 77.8459C101.007 78.4574 100.69 79.7082 99.836 79.9285Z" fill="#EF8533"/>
    <path d="M52.6234 127.293C52.4609 127.335 52.2891 127.323 52.1339 127.259L52.1243 127.255L50.0915 126.391C49.9972 126.351 49.9116 126.293 49.8398 126.22C49.7679 126.147 49.7112 126.06 49.6728 125.965C49.6344 125.87 49.6151 125.768 49.616 125.665C49.6169 125.563 49.638 125.461 49.6781 125.367C49.7182 125.272 49.7765 125.187 49.8496 125.115C49.9228 125.043 50.0093 124.986 50.1044 124.948C50.1994 124.91 50.301 124.89 50.4035 124.891C50.5059 124.893 50.6072 124.914 50.7014 124.954L52.0186 125.516L53.7447 121.447C53.7847 121.353 53.8429 121.267 53.9159 121.195C53.9889 121.124 54.0753 121.067 54.1702 121.029C54.2651 120.99 54.3665 120.971 54.4688 120.972C54.5711 120.973 54.6722 120.994 54.7664 121.034L54.7666 121.034L54.7563 121.061L54.7671 121.034C54.9571 121.115 55.1071 121.269 55.1844 121.461C55.2616 121.653 55.2597 121.868 55.179 122.058L53.1483 126.842C53.1012 126.952 53.0294 127.05 52.9385 127.128C52.8475 127.206 52.7398 127.262 52.6238 127.292L52.6234 127.293Z" fill="#EF8533"/>
    <path d="M177.669 178.87H78.5507C75.1895 178.867 71.967 177.527 69.5903 175.146C67.2135 172.764 65.8766 169.535 65.8728 166.168V49.5324C65.8766 46.1646 67.2135 42.9358 69.5903 40.5544C71.967 38.1729 75.1895 36.8334 78.5507 36.8296H170.764L190.347 52.4691V166.168C190.343 169.535 189.006 172.764 186.629 175.146C184.253 177.527 181.03 178.867 177.669 178.87Z" fill="#F9A767"/>
    <path d="M79.5504 40.4863C77.1568 40.489 74.862 41.443 73.1695 43.1388C71.477 44.8347 70.5249 47.134 70.5222 49.5323V166.167C70.5249 168.566 71.477 170.865 73.1695 172.561C74.862 174.257 77.1568 175.211 79.5504 175.213H178.669C181.062 175.211 183.357 174.257 185.05 172.561C186.742 170.865 187.694 168.566 187.697 166.167V54.2306L170.487 40.4863H79.5504Z" fill="#F9A767"/>
    <path d="M158.39 74.4009H113.263C112.703 74.4009 112.166 74.178 111.77 73.7813C111.375 73.3846 111.152 72.8465 111.152 72.2855C111.152 71.7245 111.375 71.1864 111.77 70.7897C112.166 70.393 112.703 70.1702 113.263 70.1702H158.39C158.95 70.1702 159.487 70.393 159.883 70.7897C160.279 71.1864 160.502 71.7245 160.502 72.2855C160.502 72.8465 160.279 73.3846 159.883 73.7813C159.487 74.178 158.95 74.4009 158.39 74.4009Z" fill="white"/>
    <path d="M167.099 81.54H113.263C112.704 81.5393 112.167 81.3161 111.772 80.9195C111.377 80.5228 111.155 79.9852 111.155 79.4247C111.155 78.8641 111.377 78.3265 111.772 77.9299C112.167 77.5333 112.704 77.3101 113.263 77.3093H167.099C167.377 77.309 167.651 77.3634 167.908 77.4695C168.164 77.5757 168.397 77.7314 168.594 77.9279C168.79 78.1244 168.946 78.3577 169.052 78.6145C169.158 78.8713 169.213 79.1466 169.213 79.4247C169.213 79.7027 169.158 79.978 169.052 80.2349C168.946 80.4917 168.79 80.725 168.594 80.9215C168.397 81.118 168.164 81.2737 167.908 81.3798C167.651 81.486 167.377 81.5404 167.099 81.54Z" fill="white"/>
    <path d="M158.391 102.164H113.265C112.705 102.164 112.168 102.387 111.772 102.783C111.376 103.18 111.154 103.718 111.154 104.279C111.154 104.84 111.376 105.378 111.772 105.775C112.168 106.171 112.705 106.394 113.265 106.394H158.391C158.95 106.394 159.487 106.171 159.883 105.775C160.279 105.378 160.502 104.84 160.502 104.279C160.502 103.718 160.279 103.18 159.883 102.783C159.487 102.387 158.95 102.164 158.391 102.164Z" fill="white"/>
    <path d="M167.1 109.304H113.265C112.705 109.304 112.168 109.527 111.772 109.924C111.376 110.32 111.154 110.858 111.154 111.419C111.154 111.98 111.376 112.518 111.772 112.915C112.168 113.312 112.705 113.535 113.265 113.535H167.1C167.66 113.535 168.197 113.312 168.593 112.915C168.989 112.518 169.211 111.98 169.211 111.419C169.211 110.858 168.989 110.32 168.593 109.924C168.197 109.527 167.66 109.304 167.1 109.304Z" fill="white"/>
    <path d="M158.39 138.391H113.263C112.703 138.391 112.166 138.168 111.77 137.771C111.375 137.374 111.152 136.836 111.152 136.275C111.152 135.714 111.375 135.176 111.77 134.779C112.166 134.383 112.703 134.16 113.263 134.16H158.39C158.95 134.16 159.487 134.383 159.883 134.779C160.279 135.176 160.502 135.714 160.502 136.275C160.502 136.836 160.279 137.374 159.883 137.771C159.487 138.168 158.95 138.391 158.39 138.391Z" fill="white"/>
    <path d="M167.099 145.53H113.263C112.704 145.529 112.167 145.306 111.772 144.909C111.377 144.513 111.155 143.975 111.155 143.414C111.155 142.854 111.377 142.316 111.772 141.92C112.167 141.523 112.704 141.3 113.263 141.299H167.099C167.377 141.299 167.651 141.353 167.908 141.459C168.164 141.565 168.397 141.721 168.594 141.918C168.79 142.114 168.946 142.347 169.052 142.604C169.158 142.861 169.213 143.136 169.213 143.414C169.213 143.692 169.158 143.968 169.052 144.225C168.946 144.481 168.79 144.715 168.594 144.911C168.397 145.108 168.164 145.263 167.908 145.37C167.651 145.476 167.377 145.53 167.099 145.53Z" fill="white"/>
    <path d="M94.471 83.3317C98.5921 83.3317 101.933 79.9843 101.933 75.8551C101.933 71.7258 98.5921 68.3784 94.471 68.3784C90.3499 68.3784 87.009 71.7258 87.009 75.8551C87.009 79.9843 90.3499 83.3317 94.471 83.3317Z" fill="white"/>
    <path d="M93.7316 78.7073C93.5637 78.7076 93.4003 78.6532 93.266 78.5523L93.2577 78.546L91.504 77.2019C91.4225 77.1396 91.3541 77.0618 91.3027 76.973C91.2513 76.8842 91.2179 76.7861 91.2043 76.6843C91.1908 76.5825 91.1974 76.479 91.2239 76.3798C91.2503 76.2806 91.296 76.1876 91.3583 76.1061C91.4207 76.0246 91.4985 75.9563 91.5872 75.9049C91.676 75.8536 91.774 75.8203 91.8756 75.807C91.9772 75.7936 92.0805 75.8005 92.1794 75.8272C92.2784 75.8539 92.3711 75.8999 92.4523 75.9625L93.5882 76.8353L96.2723 73.3266C96.3346 73.2453 96.4122 73.177 96.5008 73.1257C96.5893 73.0744 96.6871 73.0411 96.7886 73.0277C96.89 73.0143 96.993 73.021 97.0919 73.0475C97.1907 73.074 97.2833 73.1197 97.3645 73.1821L97.3648 73.1823L97.3481 73.2054L97.3652 73.1823C97.529 73.3084 97.6361 73.4945 97.6632 73.6997C97.6902 73.9049 97.6348 74.1124 97.5093 74.2768L94.3521 78.4019C94.2791 78.4969 94.1852 78.5739 94.0777 78.6267C93.9702 78.6795 93.852 78.7068 93.7323 78.7064L93.7316 78.7073Z" fill="#EF8533"/>
    <path d="M102.7 107.849C102.7 108.831 102.508 109.804 102.133 110.711C101.758 111.618 101.209 112.443 100.516 113.137C99.823 113.832 99.0004 114.383 98.095 114.759C97.1897 115.135 96.2192 115.328 95.2392 115.328C95.1751 115.33 95.1109 115.327 95.0471 115.321C93.5799 115.283 92.1564 114.813 90.9546 113.968C89.7529 113.124 88.8264 111.943 88.291 110.574C87.7556 109.205 87.6351 107.708 87.9447 106.27C88.2542 104.833 88.9799 103.519 90.0311 102.492C91.0823 101.466 92.4122 100.773 93.8545 100.501C95.2967 100.228 96.7872 100.387 98.1394 100.959C99.4916 101.531 100.646 102.489 101.457 103.715C102.268 104.941 102.7 106.379 102.7 107.849Z" fill="white"/>
    <path d="M94.471 147.321C98.5921 147.321 101.933 143.974 101.933 139.845C101.933 135.716 98.5921 132.368 94.471 132.368C90.3499 132.368 87.009 135.716 87.009 139.845C87.009 143.974 90.3499 147.321 94.471 147.321Z" fill="white"/>
    <path d="M189.965 52.9722H174.626C173.506 52.9722 172.432 52.5265 171.64 51.7331C170.848 50.9397 170.403 49.8636 170.403 48.7415V37.3894C170.403 37.3397 170.417 37.291 170.443 37.2489C170.47 37.2068 170.507 37.173 170.552 37.1514C170.597 37.1297 170.647 37.1211 170.696 37.1266C170.745 37.132 170.792 37.1513 170.831 37.1822L190.129 52.5006C190.172 52.5347 190.204 52.5813 190.219 52.634C190.234 52.6867 190.233 52.7429 190.215 52.7947C190.197 52.8466 190.163 52.8916 190.118 52.9234C190.074 52.9552 190.02 52.9722 189.965 52.9722Z" fill="#EF8533"/>
        </svg>


    )
}

export default AutonomieFigure;