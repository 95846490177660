import React, {useState} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Button from "react-bootstrap/cjs/Button";
import Alert from "react-bootstrap/Alert";


const ResetPassword = () => {

  const {token} = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState(false);

  const handleSubmit = (event) => {
    if (password === confirmPassword) {
      axios.post('/auth/reset/' + token, {
        password
      }).then((res) => {
        console.log(res);
        setMessage("Nouveau mot de passe enregistré.");
      }).catch((e) => {
        console.log(e);
        setErrors("Erreur.")
      });
    }
    else setErrors("Les mots de passe de correspondent pas.");

    event.preventDefault();
  }

  return <Container fluid={true}>
    <Row>
      <Col lg={5}>
        <h3><b>Nouveau mot de passe</b></h3>
        <br/>
        {errors && <Alert variant={"danger"}>{errors}</Alert>}
        {message && <Alert variant={"success"}>{message}</Alert>}
        <form onSubmit={handleSubmit} className="auth-form">
          <input
            required
            type={"password"}
            placeholder={"Mot de passe"}
            value={password}
            onChange={(event) => setPassword(event.target.value)} />
          <input
            required
            type={"password"}
            placeholder={"Confirmer le mot de passe"}
            value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
          <br/><br/>
          <Button type={"submit"} className="btn btn-blue">Valider</Button>
        </form>
      </Col>
    </Row>
  </Container>
}

export default ResetPassword;
