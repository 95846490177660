export const infoPostitOne = <>Vous avez identifié un aspect 
<br/>de votre situation professionnelle à faire évoluer ?
<br/> Vous pouvez le noter ici. Si vous le souhaitez,
<br/> vous pouvez compléter un quiz de l’activité
<br/> pour vous aider à identifier ces éléments</>
export const infoPostitTwo = <>Vous avez identifié un aspect 
<br/>de votre activité professionnelle que 
<br/>vous souhaitez préserver ? Vous pouvez le 
<br/>noter ici. Si vous le souhaitez, vous pouvez 
<br/>vous questionner sur l’une des 5 dimensions 
<br/>de votre travail pour identifier les éléments 
<br/>sur lesquels vous voulez capitaliser ou 
<br/>que vous souhaitez conserver.</>
export const infoPostitThree = <>Selon votre situation, vous pouvez 
	<br/>contacter les personnes qui vous semblent utiles
	<br/> pour alimenter votre réflexion : manager, collègues,
	<br/> personnes que vous connaissez dans d’autres services,
	<br/> collègues ayant une expérience 
	<br/>du travail avec la maladie, 
	<br/>acteurs spécialisés (RH, services de santé au travail)…</>
export const infoPostitFour = <>Une question que vous souhaitez approfondir,
<br/> une action que vous souhaitez expérimenter...
<br/>N’hésitez pas à les noter ici</>
export const infoAutonomie = <> La possibilité d’agir sur les situations 
	<br/>de travail dépend des marges de manœuvre existantes pour 
	<br/>prendre des décisions et choisir les moyens d’y faire face.
	<br/>Faire le point sur votre autonomie d’action et 
	<br/>sur vos possibilités de mobiliser vos compétences dans votre activité 
	<br/>professionnelle peut vous aider à évaluer la façon
	<br/> dont vous pouvez influer sur votre 
	<br/>environnement de travail pour le transformer.</>
export const infoCarriere = <>Le monde du travail connaît des changements
	<br/> de plus en plus rapides qui peuvent aussi 
	<br/>se produire lors de l’arrêt de travail. 
	<br/>Pour anticiper les conditions de la reprise d’activité,
	<br/> et plus globalement pour se projeter dans l’« après »,
	<br/> ces questions visent à faire le point 
	<br/>sur votre itinéraire professionnel, 
	<br/>sur les changements actuels et à venir,
	<br/> et peuvent vous aider à visualiser la façon 
	<br/>dont vous vous projetez à court ou moyen terme dans votre travail.</>
export const infoConditions = <>
	Les caractéristiques de l’environnement 
	<br/>de travail regroupent les conditions de travail 
	<br/>mais aussi les conditions de réalisation de l’activité.
	<br/> Même si vous avez l’impression de maîtriser
	<br/> ces différents aspects, 
	<br/>ceux-ci font souvent tellement partie du quotidien
	<br/> qu’on ne les voit plus. Aiguiser votre regard
	<br/> sur votre environnement, c’est vous donner les moyens d’agir
	<br/> sur les contraintes mais aussi de saisir les opportunités.</>
	export const infoRelationnel = <>Les relations au travail regroupent 
	<br/>l’ensemble des interactions que vous avez 
	<br/>dans le cadre de votre activité professionnelle 
	<br/>: avec vos collègues, votre manager ou vos subordonnés,
	<br/> vos clients ou usagers… Elles se caractérisent
	<br/> par une dimension opérationnelle 
	<br/>(possibilités de coopération) et relationnelle.
	<br/> Analyser vos relations professionnelles peut faciliter
	<br/> l’identification des personnes sur lesquelles
	<br/> vous pouvez vous appuyer et préciser 
	<br/>la nature des relations que vous 
	<br/>souhaitez (re)construire avec elles.</>
export const infoTemps = <>Le travail est composé de différentes temporalités :
	<br/> la durée du travail, les horaires de travail,
	<br/> le rythme de travail… 
	<br/>Lorsque l’on parle d’aménagement du temps de travail,
	<br/> via le temps partiel thérapeutique par exemple,
	<br/> celui-ci ne tient compte que d’une seule dimension : 
	<br/>la durée de travail. Une investigation large des temps de travail
	<br/> peut vous permettre de mieux ajuster 
	<br/>votre propre rythme aux rythmes de votre activité
	<br/> et d’agir sur les bons leviers temporels.</>

