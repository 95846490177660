import React, {useContext, useState} from "react";
import axios from "axios";
import {Link, Redirect, useHistory} from "react-router-dom";
import {Context} from "../../state/Store";
import Alert from "react-bootstrap/Alert";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
cgu_agreement,
cgu_pdc_required,
double_opt_in_mail_sent,
pdc_agreement,
} from "../../texts";
import Modal from "react-bootstrap/cjs/Modal";
import Accordion from "react-bootstrap/cjs/Accordion";
import Button from "react-bootstrap/cjs/Button";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import subscribe_img from "../../img/subscribe.svg";
import success_img from "../../img/success-account.svg";
const SignUp = (props) => {

const history = useHistory();
const [state] = useContext(Context);
const [firstName, setFirstName] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [cguAgreement, setCguAgreement] = useState(false);
const [pdcAgreement, setPdcAgreement] = useState(false);
const [info, setInfo] = useState(null);
const [errors, setErrors] = useState(null);
const [show, setShow] = useState(false);

const handleSubmit = (event) => {
const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
if (passwordRegex.test(password)) {
if (password === confirmPassword) {
if (cguAgreement && pdcAgreement) {
axios.post(
'/auth/signup',
{firstName, email, password, cguAgreement, pdcAgreement}
).then(() => setShow(true))
.catch((e) => {
if (e.response?.status === 409)
setErrors("Un compte est déjà associé à cet email.")
else setErrors("Erreur serveur.")
});
}
else setInfo(cgu_pdc_required);
}
else setErrors("Les mots de passe de correspondent pas.")
}
else setErrors("Le mot de passe doit comporter au moins 8 caractères, dont au moins un numéro, une majuscule et une minuscule.");
event.preventDefault();
}

return <>{state.user ? <Redirect to={"/" + props.from} /> :
<>

  <Container fluid={true}>
    <Row className="justify-center">
    <Col lg={6} className="my-auto"><div className="intro-img">
      <img src={subscribe_img} alt="logo" className="intro-logo"/>
    </div>
  </Col>
    <Col lg={6} className="auth-block px-3 my-4 align-self-end">
    <h2>Inscrivez-vous</h2>
    {errors && <Alert variant={"danger"}>
      {errors}
    </Alert>}
    <form className="auth-form" onSubmit={handleSubmit}>

      <div>
      <label className="label-auth">Prénom</label>
      </div>
      <input className="input-text" required value={firstName} onChange={(event => setFirstName(event.target.value))} /><br/>
      <div><label className="label-auth">Email</label></div>
      <input className="input-text"required type={"email"} value={email} onChange={(event) => setEmail(event.target.value)} /><br/>
     <div><label className="label-auth">Mot de passe</label></div>
      <input className="input-text" required
      type={"password"}
      value={password}
      onChange={(event) => setPassword(event.target.value)} /><br/>
      <div><label className="label-auth">Confirmez le mot de passe</label></div>
      <input className="input-text" required type={"password"}  value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
      {info && <Alert variant={"info"}>
        {info}
      </Alert>}
      <Row className="mt-5 text-left">

        <div className="col-1">
        <input type={"checkbox"} checked={cguAgreement} onChange={(e) => setCguAgreement(e.target.checked)}/>
      </div>
      <div className="col-11">
      <label className="cgu-label">{cgu_agreement}</label>
    </div>
    </Row>
    
    <Row className="mt-3 text-left">
      <div className="col-1">
      <input type={"checkbox"} checked={pdcAgreement} onChange={(e) => setPdcAgreement(e.target.checked)}/>
    </div>
       <div className="col-11">
      <label className="cgu-label">{pdc_agreement}</label>
    </div>
    </Row>
    <Col lg={12} className="text-center submit-auth">
    <button type="submit" class="btn btn-blue btn btn-primary">Je valide mon inscription</button>
  </Col>
     </form>
</Col>
</Row>
</Container>

<Modal centered show={show} onHide={() => {
        setShow(false);
        history.push("/");
      }} style={{'maxWidth': 'inherit !important;'}}>
<div className="bg-white p-2 rounded">
<Modal.Header closeButton>
<Modal.Title>Inscription réussie!</Modal.Title>
</Modal.Header>
<Modal.Body>
<Row>
<Col lg={6}>
{double_opt_in_mail_sent}
</Col>
<Col lg={6} className="align-self-end">
<img src={success_img} style={{"width":"-webkit-fill-available"}}/>
</Col>

</Row>
</Modal.Body>
</div>
</Modal>
</>}
</>
}

export default SignUp;
