import React from "react";
import {email} from "./_params";

export const stories_left_title = <>
  Les récits
</>

export const stories_left_description = <>
  <p className="header-text">
  Découvrez, à travers ces récits d’expérience du travail avec ou après la maladie, les pratiques singulières élaborées par les salariés et les managers pour articuler travail et santé.
Vous pouvez interagir avec le contenu : surligner les passages qui vous intéressent, insérer des notes… <br/>Toutes ces actions viendront enrichir votre espace personnel.</p>
</>

export const stories_intro = <>
  <b>Découvrez</b>, à travers ces récits, les pratiques singulières élaborées par les salarié.e.s et les managers
  pour articuler travail et santé.
</>

export const stories_categories_title = <>
 Thématiques
</>

export const stories_explanation = <>
  Comment faire pour travailler avec un cancer ou une maladie chronique ? L’expérience des salarié.e.s qui ont eux-mêmes
  été concerné.e.s directement ou indirectement par la situation est essentielle pour appréhender les différentes modalités
  d’action possibles sans présager d’emblée d’une solution miracle. Lire et réfléchir à partir de ces récits, c’est entamer
  un travail d’interrogation de votre activité pour initier votre propre travail de santé.
</>

export const stories_filter = (keyword) => <>
  Pour explorer le sujet  <b>”{keyword}”</b>, plongez-vous dans les récit(s) suivant(s) :
</>

export const stories_filter_footer = <>
  Retour à l'ensemble des récits
</>

export const story_display_footer = <>
  Retourner à l'ensemble des récits
</>

export const stories_footer = <>
  Vous souhaitez nous partager votre récit ? <br/>
  <a href={`mailto:${email}?subject=${encodeURIComponent("Partage d'un récit d'expérience")}`}>
    <b>Ecrivez-nous!</b>
  </a>
</>

