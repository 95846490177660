import React, {useRef,useState,useEffect,useContext} from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import ReactQuill from "react-quill";
import "./quill.css"
import Modal from "react-bootstrap/cjs/Modal";
import ReactHTMLParser from 'react-html-parser';
import {Context} from "../../state/Store";
import info from "../../img/info-bulle.svg";
import {Link} from 'react-router-dom';
import dateFormat from "dateformat";
import '@blueprintjs/core/lib/css/blueprint.css';
import { Tooltip, Button } from "@blueprintjs/core";

import { Accordion, Card } from "react-bootstrap";

import {
  tableau_header,
  dashboard_postits_describe,
  score_low_title,
  quizz_intro,
  empty_story,
  infoPostitOne,
  infoPostitTwo,
  infoPostitThree,
  infoPostitFour,
  infoAutonomie,
  infoCarriere,
  infoConditions,
  infoRelationnel,
  infoTemps
} from "../../texts";

import 'rc-slider/assets/index.css';


import dashboardHeader from "../../img/dashboard_header.svg";
import {ReactComponent as ProgressLogoOne} from "../../img/progress-display-one.svg";
import heartIcon from "../../img/heart_black.svg";
import contact from "../../img/contact.svg";
import StoryBox from './StoryBox';
import AutonomieFigure from './AutonomieFigure';
import ConditionFigure from './ConditionFigure';
import RelationnelFigure from './RelationnelFigure';
import DisplayAutonomie from './DisplayAutonomie';
import DisplayCondition from './DisplayCondition';
import DisplayRelationnel from './DisplayRelationnel';
import DisplayTemps from './DisplayTemps';
import DisplayCarriere from './DisplayCarriere';

import Display from './Display';

import {ReactComponent as Indicator} from "../../img/indicator.svg";
import autonomieResult from "../../img/barometer-resultat.svg";
import favorisVide from "../../img/favoris-vide.svg";
import temps from "../../img/temps.svg";
import conditions from "../../img/conditions.svg";

import close from "../../img/close.svg";





const Dashboard = () => {


  const [favorites, setFavorites] = useState([]);
  const [state, dispatch] = useContext(Context);
  const [postitnotes,setPostitNotes] = useState('');
  const [postitnotestwo,setPostitNotesTwo] = useState('');
  const [postitnotesthree,setPostitNotesThree] = useState('');
  const [postitnotesfour,setPostitNotesFour] = useState('');
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [sliderPosition, setSliderPosition] =  useState(7.5);

  const [sliderPositionImg, setSliderPositionImg] =  useState(7.5);
  const [progressScores,setProgressScores] = useState([null,null,null,null,null]);
  const [dimension, setDimension] = useState(()=>new Map());
  const [width, setWidth]   = useState(window.innerWidth);
  const [hearts] = useState([]);
  const [date, setDate] = useState(null);
  const [link, setLink] = useState(null);

    const [infoOne, setInfoOne] = useState(false);
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };
  const formats = ["bold","underline","italic","header","size"];

  const modules= {
    toolbar: [
      ["bold","underline","italic"],
      [{ 'size': [false, 'large'] }]
    ]
  };
    const handleContact = () => {
    document.location.href = `mailto:contact@934-travail-sante.fr`;
  }
  const colours = {
    0: "#E9E9E9",
    1: "#EF8533",
    2: "#E5D140",
    3: "#E5D140",
    4: "#85DB43"
  };

  const [name, setName] = useState(state.user?.firstName);
  
  useEffect(()=> {
    var index = 0;
    var storyId = state.user.favoriteStories;
    storyId.forEach(story => {

      axios.get("/story/get/"+ story).then((res) => {
        hearts.push({id: res.data._id, title: res.data.title, phase: res.data.phase.title});
      });
      index++;
    });
    console.log("ici test = ", hearts);
},[hearts]);


  useEffect(()=> {
    axios.get(
      '/barometer/score',
    ).then((res) => {
      if (res.data.answers) {
        setDate(res.data.updatedAt);
        setProgressScores(res.data.answers);
        console.log("progress", res.data.answers);
      } else {
      }
    }).catch((e) => {
    })
  },[])

    useEffect(()=> {
    axios.get(
      '/dimensions/',
    ).then((res) => {
        res.data.forEach((e) => {
        setDimension(new Map(dimension.set(e.title, {id:e._id})))
        setSliderPosition(1);
      })
    }).catch((e) => {
    })
    console.log(dimension)
  },[])

  useEffect(() => {
    axios.get('/postits/').then((res) => {
        (res.data.length>0) ? 
        res.data.forEach((e) => {
          if (e.category==='one') {
            setPostitNotes(e.notes);
          //  console.log(true);
          } else if (e.category==='two') {
            setPostitNotesTwo(e.notes);
          //  console.log('two');
          } else if (e.category==='three') {
            setPostitNotesThree(e.notes);
            console.log('three');
          } else if (e.category==='four') {
            setPostitNotesFour(e.notes);
          //  console.log('four');
          }
        //  console.log(e);
        })
        : updateAll();
    })
  },[]);



  const updateAll = () => {
    updateNotes();
    updateNotesTwo();
    updateNotesThree();
    updateNotesFour();
  }

  const updateNotes = () => {
    axios.put(
      '/postits/update',{category:"one",notes:postitnotes}
    ).then((res) => {
     // console.log(res);
    }).catch((e) => {
     // console.log(e);
    })
  }

  const updateNotesTwo = () => {
    axios.put(
      '/postits/update',{category:"two",notes:postitnotestwo}
    ).then((res) => {
     // console.log(res);
    }).catch((e) => {
     // console.log(e);
    })
  }

  const updateNotesThree = () => {
    axios.put(
      '/postits/update',{category:"three",notes:postitnotesthree}
    ).then((res) => {
      //console.log(res);
    }).catch((e) => {
      //console.log(e);
    })
  }

  const updateNotesFour = () => {
    axios.put(
      '/postits/update',{category:"four",notes:postitnotesfour}
    ).then((res) => {
      //console.log(res);
    }).catch((e) => {
      //console.log(e);
    })
  }

  const handleSliderChange = (e) => {

      setSliderPosition(e);
      if (e == 2)
        setSliderPositionImg(27.5)
      else if(e == 3)
        setSliderPositionImg(47.5)
      else if(e == 4)
        setSliderPositionImg(67.5)
      else if(e == 5)
        setSliderPositionImg(87.5)
      else
        setSliderPositionImg(7.5)
    //}
  }


  const conditionCheck = () => {
    const reducer = (sum,val) => sum*val;
    let condition=progressScores.reduce(reducer,1);
    return condition;
  }



  const getTextTitle = (val) => {
    if (val==null)
      return "blabla"
    else if (val == 1)
      return "Tout reste à faire... et vous pouvez y arriver !"
    else if (val == 2)
      return "Des aménagements peuvent être envisagés."
    else if (val == 3)
      return "Des aménagements peuvent être envisagés."
    else if (val == 4)
      return "Bonne nouvelle, les voyants sont au vert !"
    else
      return "blabla"
  }

  const getText = (val) => {
    if (val==null)
      return "blabla"
    else if (val == 1)
      return "Quand c’est orange ou rouge, cela signifie que cette dimension de votre activité pèse sur les conditions de votre reprise et sur les possibilités que vous identifiez de pouvoir faire votre travail en santé. Vous pouvez creuser votre investigation en vous questionnant plus précisément sur les éléments sur lesquels vous pensez qu’une action serait utile ou nécessaire."
    else if (val == 2)
      return "Si de la route reste à faire, vous allez dans la bonne direction. Sillonnez encore un peu cette dimensions de votre activité, des chemins de traverse pourraient bien se révéler à vous !"
    else if (val == 3)
      return "Si de la route reste à faire, vous allez dans la bonne direction. Sillonnez encore un peu cette dimensions de votre activité, des chemins de traverse pourraient bien se révéler à vous !"
    else if (val == 4)
      return "Quand c’est vert, c’est que cette dimension de votre activité fonctionne comme une ressource pour vous. Vous pouvez peut-être l’exploiter davantage et creuser votre investigation en vous questionnant plus précisément sur les éléments sur lesquels vous souhaitez agir."
    else
      return "blabla"
  }

  const getScore = (val) => {
   // console.log(val);
    if (val==null) {
      return 0
    } else if (val<=-1) {
      return 1
    } else if (val<0.5) {
      return 2
    } else if (val<1) {
      return 3
    } else if (val>=1) {
      return 4
    } else {
      return 0
    }
  }
  const changeInfo = (val) => {
    console.log("ici change info ", val);

  }
  function getRecitslink(val) {
    
  }
  
  function get_result_text(val) {
    if (val== 1) {
      return (<p className="result">Tout à fait inadapté</p>)
    } else if (val == 2) {
      return (<p className="result">Inadapté</p>)
    } else if (val == 3) {
      return (<p className="result">Adapté</p>)
    } else if (val == 4) {
      return (<p className="result">Tout à fait adapté</p>)
    } else {
      return 
    }
    return (<p>test</p>)
  }
  const getStories = (val) => {
    return val.id
  }
  const getColouredCircle = (score,id) => {
    //Assign an SVG shape at random
    
    switch(id) {
      case 1: return (<div id={id} onClick={()=>handleSliderChange(1)} onMouseEnter={()=>handleSliderChange(1)} onMouseLeave={()=>handleSliderChange(1)}>
                        <DisplayAutonomie  id={id} fill={colours[getScore(score)]} score={getScore(score)}/>
                      </div>);
      case 2: return (<div id={id} onClick={()=>handleSliderChange(2)} onMouseEnter={()=>handleSliderChange(2)} onMouseLeave={()=>handleSliderChange(2)}>
                        <DisplayCondition  id={id} fill={colours[getScore(score)]} score={getScore(score)}/>
                      </div>);
      case 3: return (<div id={id} onClick={()=>handleSliderChange(3)} onMouseEnter={()=>handleSliderChange(3)} onMouseLeave={()=>handleSliderChange(3)}>
                        <DisplayRelationnel  id={id} fill={colours[getScore(score)]} score={getScore(score)}/>
                      </div>);
      case 4: return (<div id={id} onClick={()=>handleSliderChange(4)} onMouseEnter={()=>handleSliderChange(4)} onMouseLeave={()=>handleSliderChange(4)}>
                        <DisplayTemps  id={id} fill={colours[getScore(score)]} score={getScore(score)}/>
                      </div>);
      case 5: return (<div id={id} onClick={()=>handleSliderChange(5)} onMouseEnter={()=>handleSliderChange(5)} onMouseLeave={()=>handleSliderChange(5)}>
                        <DisplayCarriere id={id} fill={colours[getScore(score)]} score={getScore(score)}/>
                      </div>);
      default: return <ProgressLogoOne className="intro-logo pr-2" id={id} fill={colours[score]} width="20%" height="80%" onClick={()=>handleSliderChange(1)} onMouseEnter={()=>handleSliderChange(1)}/>;
    }
  }
  const getmobileCirle = (score,id) => {
 return (<div id={id}>
                        <Display  id={id} fill={colours[score]} score={getScore(score)}/>
                      </div>);
        }

  const img_figure_result = (val) => {
    console.log(val)
    if (getScore(val) == 1)
      return (<AutonomieFigure fill={colours[getScore(progressScores[0])]}></AutonomieFigure>)
    else if (getScore(val) == 2)
      return (<RelationnelFigure fill={colours[getScore(progressScores[4])]}></RelationnelFigure>)
    else if (getScore(val) == 3) 
      return (<RelationnelFigure fill={colours[getScore(progressScores[4])]}></RelationnelFigure>)
     else if (getScore(val) == 4) 
      return (<ConditionFigure fill={colours[getScore(progressScores[1])]}></ConditionFigure>)
  }
  return <>
    
        <div className="intro pb-5">
          <Container fluid={true}>
            <Row className="pb-5">
              <Col>
                <h1>Bonjour {name},</h1>
                {tableau_header}
              </Col>
              <div className="intro-img">
                <img src={dashboardHeader} alt="logo" className="intro-logo"/>
              </div>
            </Row>
          </Container>
        </div>
        { width > 1200?
          <>
        <div className="intro-dashboard pb-5">
          <Container fluid={true}>
            <Row>
              <Col className="ml-5">
                  <h2>Votre baromètre en 5 dimensions</h2>
                  {progressScores[0]?
                  <p>Votre activité professionnelle vous semble-t-elle adaptée à vos besoin ? Voici de premiers éléments pour en juger, sur la base de vos réponses aux 20 questions du baromètre. </p>
                  :
                  <h2 className="dashboard-initial">vous n'avez pas encore initialisé cet espace</h2>
                }
              </Col>
            </Row>
            <Row className="d-flex justify-content-end w-100 pb-5 pt-3" width="100%">
              {progressScores.map((number,i)=>{
                  return getColouredCircle(number,i+1)
                })
              }
            </Row>

            { progressScores[0]?
            <Row className="barometer-summary m-0 p-0">
              <Indicator className="intro-logo-indicator" height="50px" style={{left:sliderPositionImg+"%"}}/>
              <Row>
                {sliderPosition===1 ?
                <>
                  <Col>
                  <h2 className="mb-4">Autonomie<Tooltip content={infoAutonomie} position='right'><img src={info} alt="info"/></Tooltip></h2>
        
                  <p>{getText(getScore(progressScores[0]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Autonomie")?.id}>
                    <button className="btn mr-2 mb-5">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Autonomie")?.id}>
                    <button className="btn btn-white mb-5">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  <div className="game-card pr-5">
                  {img_figure_result(progressScores[0])}
                  

                <p className="mt-5">Dernier relevé : {dateFormat(date, "d/mm/yyyy")} <a href="/barometre/nouveau"> Actualiser vos réponses</a></p>
                </div>
                </> :
                sliderPosition===2 ?
                <>
                  <Col>
                  <h2 className="mb-4">Conditions de travail<Tooltip content={infoConditions} position='right'><img src={info} alt="info"/></Tooltip></h2>
        
                  <p>{getText(getScore(progressScores[1]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Conditions de travail")?.id}>
                    <button className="btn mr-2 mb-5">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Conditions de travail")?.id}>
                    <button className="btn btn-white mb-5">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  <div className="intro-img pr-5">
                  {img_figure_result(progressScores[1])}
                    <p className="mt-5">Dernier relevé : {dateFormat(date, "d/mm/yyyy")} <a href="/barometre/nouveau"> Actualiser vos réponses</a></p>
                  
             
                </div>
                </> :
                sliderPosition===3 ?
                <>
                  <Col>
                  <h2 className="mb-4">Relationnel<Tooltip content={infoRelationnel} position='right'><img src={info} alt="info"/></Tooltip></h2>
        
                  <p>{getText(getScore(progressScores[2]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Relationnel")?.id}>
                    <button className="btn mr-2 mb-5">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Relationnel")?.id}>
                    <button className="btn btn-white mb-5">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  <div className="intro-img pr-5">
                    <RelationnelFigure fill={colours[getScore(progressScores[2])]}></RelationnelFigure>
                    <p className="mt-5">Dernier relevé : {dateFormat(date, "d/mm/yyyy")} <a href="/barometre/nouveau"> Actualiser vos réponses</a></p>
             
                  </div>
                </> :
                sliderPosition===4 ?
                <>
                  <Col>
                  <h2 className="mb-4">Temps de travail<Tooltip content={infoTemps} position='right'><img src={info} alt="info"/></Tooltip></h2>
        
                  <p>{getText(getScore(progressScores[3]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Temps de travail")?.id}>
                    <button className="btn mr-2 mb-5">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Temps de travail")?.id}>
                    <button className="btn btn-white mb-5">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  <div className="intro-img pr-5">
                    <ConditionFigure fill={colours[getScore(progressScores[3])]}></ConditionFigure>
                    <p className="mt-5">Dernier relevé : {dateFormat(date, "d/mm/yyyy")} <a href="/barometre/nouveau"> Actualiser vos réponses</a></p>
             
                  </div>
                </> :
                sliderPosition===5 ?
                <>
                  <Col>
                  <h2 className="mb-4">Carrière<Tooltip content={infoCarriere} position='right'><img src={info} alt="info"/></Tooltip></h2>
        
                  <p>{getText(getScore(progressScores[4]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Carrière")?.id}>
                    <button className="btn mr-2 mb-5">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Carrière")?.id}>
                    <button className="btn btn-white mb-5">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  <div className="intro-img pr-5">
                    {img_figure_result(progressScores[4])}
                    <p className="mt-5">Dernier relevé : {dateFormat(date, "d/mm/yyyy")} <a href="/barometre/nouveau"> Actualiser vos réponses</a></p>
             
                  </div>
                </> 
                :
                <></>
              }
              </Row>
              
            </Row>
            :
              <></>
            }
          </Container>

        </div>
        </>
        :
        <>
        <Container>
        <Accordion className="barometer-mobile py-5 bg-white">
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <Row className="justify-content-start border-card mx-2">
                    <Col className="col-auto text-right align-top">
                    {getmobileCirle(getScore(progressScores[0]), 0)}
                    </Col>
                    <Col className="col-auto text-left mt-1">
                    <p className="dimension-title">Autonomie</p>
                    {get_result_text(getScore(progressScores[0]))}
                    </Col>
                  </Row>
                </Accordion.Toggle>
                { progressScores[0]?
                <Accordion.Collapse eventKey="0" className="pb-3 w-100">

                    <Card.Body>
                    <Row>
                  <Col className="text-left mx-5 w-100">
                  
                  <p>{getText(getScore(progressScores[0]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Autonomie")?.id}>
                    <button className="btn mr-2 mb-3 w-100">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Autonomie")?.id}>
                    <button className="btn btn-white w-100">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                </Row>
                </Card.Body>
                </Accordion.Collapse>
                :
                <>
                </>
              }
            </Card>

            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                <Row className="justify-content-start border-card mx-2">
                    <Col className="col-auto text-right align-top">
                    {getmobileCirle(getScore(progressScores[1]), 1)}
                    </Col>
                    <Col className="col-auto text-left mt-1">
                   <p className="dimension-title">Conditions de travail</p>{get_result_text(getScore(progressScores[1]))}
                   </Col>
                   </Row>
                </Accordion.Toggle>
                { progressScores[0]?
                <Accordion.Collapse eventKey="1" className="pb-3 w-100">
                    <Card.Body>
                    <Row>
                    <Col className="text-left mx-5 w-100">
                  
                  <p>{getText(getScore(progressScores[1]))}</p>
                  <Link to={"/quizz/"+dimension.get("Conditions de travail")?.id}>
                    <button className="btn mr-2 mb-3 w-100">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Conditions de travail")?.id}>
                    <button className="btn btn-white w-100">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  
                </Row></Card.Body>
                </Accordion.Collapse>
                :
                <>
                </>
              }
            </Card>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                   <Row className="justify-content-start border-card mx-2">
                    <Col className="col-auto text-right align-top">
                    {getmobileCirle(getScore(progressScores[2]), 0)}
                    </Col>
                    <Col className="col-auto text-left mt-1">
                    <p className="dimension-title">Relationnel</p>{get_result_text(getScore(progressScores[2]))}
                    </Col>
                    </Row>
                </Accordion.Toggle>
                { progressScores[0]?
                <Accordion.Collapse eventKey="2" className="pb-3 w-100">
                    <Card.Body>
                    <Row>
                    <Col className="text-left mx-5 w-100">
                  
                  <p>{getText(getScore(progressScores[2]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Relationnel")?.id}>
                    <button className="btn mr-2 mb-3 w-100">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Relationnel")?.id}>
                    <button className="btn btn-white w-100">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  
                  </Row>
                  </Card.Body>
                </Accordion.Collapse>
                :
                <>
                </>
              }
            </Card>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                <Row className="justify-content-start border-card mx-2">
                    <Col className="col-auto text-right align-top">
                    {getmobileCirle(getScore(progressScores[3]), 0)}
                    </Col>
                    <Col className="col-auto text-left mt-1">
                    <p className="dimension-title">Temps de travail</p>{get_result_text(getScore(progressScores[3]))}
                    </Col>
                    </Row>
                </Accordion.Toggle>
                { progressScores[0]?
                <Accordion.Collapse eventKey="3" className="pb-3 w-100">
                    <Card.Body>
                    <Row>
                    <Col className="text-left mx-5 w-100">
                  
                  <p>{getText(getScore(progressScores[3]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Temps de travail")?.id}>
                    <button className="btn mr-2 mb-3 w-100">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Temps de travail")?.id}>
                    <button className="btn btn-white w-100">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                  
                  </Row>
                  </Card.Body>
                </Accordion.Collapse>
                :
                <>
                </>
              }
            </Card>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                    <Row className="justify-content-start border-card mx-2">
                    <Col className="col-auto text-right align-top">
                    {getmobileCirle(getScore(progressScores[4]), 0)}
                    </Col>
                    <Col className="col-auto text-left mt-1">
                    <p className="dimension-title">Carrière</p>{get_result_text(getScore(progressScores[4]))}
                    </Col>
                    </Row>
                </Accordion.Toggle>
                { progressScores[0]?
                <Accordion.Collapse eventKey="4" className="pb-3 w-100">
                    <Card.Body>
                    <Row>
                    <Col className="text-left mx-5 w-100">
                  
                  <p>{getText(getScore(progressScores[4]))}</p>
                  <h3 className="mb-4">{quizz_intro}</h3>
                  <Link to={"/quizz/"+dimension.get("Carrière")?.id}>
                    <button className="btn mr-2 mb-3 w-100">J’analyse ma situation</button>
                  </Link>
                  <Link to={"/recits/exploration/"+dimension.get("Carrière")?.id}>
                    <button className="btn btn-white w-100">Lire des récits sur le sujet</button>
                  </Link>
                  </Col>
                 
                  </Row>
                  </Card.Body>
                </Accordion.Collapse>
                :
                <>
                </>
              }
            </Card>
        </Accordion>
        </Container>
                </>
      }
        { !progressScores[0]?
          <Container>
          <Row className="mb-5"> 
            <Col lg={12} className="text-center">
            <Link to="/barometre/nouveau">
              <button className="btn btn-primary">
                Je réalise ma première photo instantané
              </button>
            </Link>
            </Col>


          </Row>
          </Container>
          :
          <>

          </>
        }

        <div className="block-transition-three">
        <Container fluid={true}>
        <Row>
          <div id="formulaire">
            <div className="intro-dashboard-postits pt-5">
              {dashboard_postits_describe}
            </div>
            <Modal classname="postit-modal" className="w-100" centered show={show} onHide={() => setShow(false)} backdrop="static" >
              <div className="quill-container">
              <img className="float-right cursor-pointer" src={close} onClick={()=>setShow(false)}/>
              <h3>À faire évoluer</h3>
                <ReactQuill
                  defaultValue={postitnotes}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotes}
                  style={{minHeight: '300px'}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShow(false)}>Annuler</button>
                <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotes();setShow(false)}}>Enregistrer</button>
                </Row>
                </div>
            </Modal>
            <Modal classname="postit-modal" centered show={showTwo} onHide={() => setShowTwo(false)} backdrop="static" >
              <div className="quill-container">
              <h3>À préserver</h3>
                <ReactQuill
                  defaultValue={postitnotestwo}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotesTwo}
                  style={{minHeight: '300px'}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                  <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShowTwo(false)}>Annuler</button>
                  <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotesTwo();setShowTwo(false)}}>Enregistrer</button>
                </Row>
              </div>
            </Modal>
            <Modal classname="postit-modal" centered show={showThree} onHide={() => setShowThree(false)} backdrop="static" >
              <div className="quill-container">
              <h3>À contacter</h3>
                <ReactQuill
                  defaultValue={postitnotesthree}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotesThree}
                  style={{minHeight: 300}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                  <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShowThree(false)}>Annuler</button>
                  <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotesThree();setShowThree(false)}}>Enregistrer</button>
                </Row>
              </div>
            </Modal>
            <Modal classname="postit-modal" centered show={showFour} onHide={() => setShowFour(false)} backdrop="static" >
              <div className="quill-container">
              <h3>Notes pour plus tard</h3>
                <ReactQuill
                  defaultValue={postitnotesfour}
                  placeHolder="Empty"
                  formats={formats}
                  modules={modules}
                  onChange={setPostitNotesFour}
                  style={{minHeight: '300px'}}
                >
                </ReactQuill>
                <Row className="d-flex justify-content-center">
                  <button className="pr-5 pl-5 btn-second mx-5" onClick={()=>setShowFour(false)}>Annuler</button>
                  <button className="pr-5 pl-5 btn btn-primary mx-5" onClick={()=> {updateNotesFour();setShowFour(false)}}>Enregistrer</button>
                </Row>
              </div>
            </Modal>
            <Container>
              <Row className="barometer-question">
            
                    <Row className="d-flex justify-content-end w-100">
                      
                      <Col lg={6}>
                      
                      <p className="title-postit">À faire évoluer <Tooltip content={infoPostitOne} position='right'><img src={info} alt="info"/></Tooltip></p>
                      <div id="categoryone" className="postit" onClick={()=>setShow(true)}>
                        {ReactHTMLParser(postitnotes)}
                      </div>
                      </Col>
                      <Col lg={6}>
                      <p className="title-postit">À préserver <Tooltip content={infoPostitTwo} position='right'><img src={info} alt="info"/></Tooltip></p>
                      <div id="categorytwo" className="postit" onClick={()=>setShowTwo(true)}>{ReactHTMLParser(postitnotestwo)}</div>
                      
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-end w-100">
                    <Col lg={6}>
                      <p className="title-postit">À contacter <Tooltip content={infoPostitThree} position='right'><img src={info} alt="info"/></Tooltip></p>
                      <div id="categorythree" className="postit" onClick={()=>setShowThree(true)}>{ReactHTMLParser(postitnotesthree)}</div>
                      </Col>
                    <Col lg={6}>
                      <p className="title-postit">Note pour plus tard <Tooltip content={infoPostitFour} position='right'><img src={info} alt="info"/></Tooltip></p>
                      <div id="categoryfour" className="postit" onClick={()=>setShowFour(true)}>{ReactHTMLParser(postitnotesfour)}</div>
                    </Col>
                    </Row>
              </Row>
            </Container>
          </div>
          </Row>
        </Container>
        </div>

        <div className="intro-dashboard intro-dashboard-postits pb-3">
        <Container fluid={true}>
              <Row>
              <Col>
                <h2 className="pt-5"><img src={heartIcon} alt="info" className="pr-3"/>Vos récits favoris</h2>
                </Col>
              </Row>
              <Row>
                {hearts[0] ?
                  <>
                    <Row className="mb-5 dashboard-story">
                {hearts.map((story, index) =>
            <Col lg={5} className="stories-box mx-3" key={"story-box-" + index}>
              <StoryBox {...story}/>
            </Col>
                )}
              </Row>
                  </>
                :
                  <>
                    <Col>
                    <p>{empty_story}</p>
                    </Col>
                    <div className="intro-img">
                    <img src={favorisVide} alt="logo" className="intro-logo"/>
                    </div>
                  </>
                }
                
              </Row>
              <Row className="d-flex justify-content-center">
                <a href="/recits" className="btn btn-primary p-3 pr-5 pl-5 mb-5">Voir l’ensemble des récits</a> 
              </Row>
        </Container>
        </div>

        <div className="ressource-dashboard d-none">
          <Container fluid={true}>

            <Row className="pb-5">
            <Col>
              <h2 className="pt-5 txt-dark">Espace ressources</h2>
              <h3 className="pb-5 txt-dark">des documents téléchargeables librement</h3>
            </Col>
            </Row>
          </Container>
        </div>

        <div className="intro-contact">
        <Container fluid={true}>
            <Row className="pb-5">
              <Col lg={6}>
                <h2 className="pb-5">Nous sommes à votre écoute !</h2>
                <h3 className="pb-4">
                  Une difficulté dans la prise en main des outils ?
                  <br/>
                  Un dysfonctionnement à remonter ?
                  <br/>
                  Des idées d’évolution à partager ? 
                  </h3>
                  <button className="btn btn-primary p-3 pr-5 pl-5 w-100 mt-5" onClick={handleContact}>Envoyez-nous un e-mail</button>
              </Col>
              <Col lg={6}>
              <div className="intro-img mr-5">
                <img src={contact} alt="logo" className="intro-logo"/>
              </div>
              </Col>
            </Row>
        </Container>
        </div>
      </>
}

export default Dashboard;
