import React from 'react';

const AutonomieFigure = ({fill}) => {
    return (
    <svg width="263" height="243" viewBox="0 0 263 243" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M240.188 110.252C240.188 124.597 236.797 136.405 230.914 146.52C228.972 149.852 226.768 153.023 224.323 156.003C202.696 182.503 162.489 197.007 126.149 220.5C18.8808 289.847 7.26155 178.726 12.1063 110.252C14.0605 82.6229 24.8004 57.3144 41.8628 37.9363C45.4462 33.8608 49.3171 30.0482 53.4455 26.5281C72.8163 10.001 97.8421 0 126.149 0C174.715 0 216.188 29.3465 232.632 70.7008C237.631 83.2853 240.196 96.7076 240.188 110.252Z" fill="#85DB43"/>
<path d="M150.446 24.8472L41.8623 37.9358C45.4457 33.8603 49.3165 30.0478 53.4449 26.5276L150.446 24.8472Z" fill="#85DB43"/>
<path d="M230.914 146.521C228.972 149.852 226.768 153.024 224.323 156.004L131.312 144.795L230.914 146.521Z" fill="#85DB43"/>
<path d="M236.426 82.072L78.7925 61.5886L232.632 70.7005C234.109 74.4164 235.376 78.213 236.426 82.072Z" fill="#85DB43"/>
<path d="M22.7988 29.3672C22.7988 29.6486 22.7907 29.926 22.7663 30.1993C22.643 31.8931 22.1441 33.538 21.306 35.0142C20.468 36.4905 19.3118 37.7609 17.9217 38.7328C16.5316 39.7048 14.9426 40.3539 13.2705 40.6329C11.5983 40.9118 9.8851 40.8136 8.25563 40.3454C6.62615 39.8771 5.12145 39.0507 3.85111 37.9262C2.58077 36.8017 1.57677 35.4075 0.912259 33.8451C0.247749 32.2827 -0.0605469 30.5915 0.00982708 28.8947C0.080201 27.1978 0.52748 25.5381 1.31907 24.0363C1.56416 23.5657 1.84452 23.1144 2.15774 22.6862C3.52098 20.792 5.43128 19.3622 7.631 18.5896C9.83072 17.817 12.214 17.7389 14.4594 18.3657C16.7049 18.9926 18.7044 20.2943 20.1883 22.0952C21.6722 23.8961 22.5691 26.1097 22.7581 28.4373C22.7866 28.7432 22.7988 29.0531 22.7988 29.3672Z" fill="#85DB43"/>
<path d="M22.7988 29.3677C22.7988 29.6491 22.7907 29.9264 22.7663 30.1997L5.69971 28.144L22.7581 28.4377C22.7866 28.7436 22.7988 29.0536 22.7988 29.3677Z" fill="#85DB43"/>
<path d="M18.7276 35.1233L2.93945 37.0281C2.49129 36.5275 2.08804 35.9884 1.73438 35.417L18.7276 35.1233Z" fill="#85DB43"/>
<path d="M14.249 22.4785L1.31885 24.0366C1.56394 23.566 1.8443 23.1147 2.15752 22.6865L14.249 22.4785Z" fill="#85DB43"/>
<path d="M179.025 234.988C178.893 235.236 178.756 235.478 178.607 235.707C177.705 237.146 176.495 238.364 175.064 239.275C173.633 240.185 172.017 240.764 170.335 240.97C168.652 241.176 166.945 241.003 165.337 240.464C163.729 239.925 162.262 239.034 161.042 237.855C159.822 236.676 158.879 235.239 158.283 233.649C157.687 232.06 157.453 230.356 157.597 228.664C157.741 226.972 158.26 225.334 159.116 223.868C159.972 222.402 161.144 221.146 162.546 220.191C162.983 219.891 163.441 219.624 163.918 219.392C166.009 218.359 168.365 217.994 170.67 218.344C172.974 218.695 175.116 219.745 176.806 221.353C178.496 222.962 179.654 225.051 180.122 227.338C180.59 229.626 180.346 232.002 179.424 234.147C179.306 234.431 179.172 234.71 179.025 234.988Z" fill="#85DB43"/>
<path d="M179.025 234.987C178.893 235.236 178.756 235.477 178.606 235.707L164.493 225.876L179.424 234.146C179.306 234.43 179.172 234.71 179.025 234.987Z" fill="#85DB43"/>
<path d="M172.736 238.158L157.899 232.426C157.737 231.773 157.633 231.108 157.588 230.437L172.736 238.158Z" fill="#85DB43"/>
<path d="M174.696 224.887L162.546 220.191C162.983 219.89 163.441 219.623 163.918 219.392L174.696 224.887Z" fill="#85DB43"/>
<path d="M259.763 38.1163C259.959 38.3177 260.146 38.522 260.319 38.7347C261.411 40.0336 262.201 41.5601 262.629 43.2032C263.058 44.8462 263.116 46.5644 262.798 48.2326C262.48 49.9008 261.795 51.477 260.792 52.8464C259.79 54.2157 258.495 55.3438 257.002 56.1484C255.509 56.953 253.856 57.4138 252.163 57.4974C250.47 57.5809 248.779 57.2851 247.215 56.6314C245.65 55.9777 244.251 54.9826 243.119 53.7186C241.986 52.4546 241.15 50.9535 240.67 49.3247C240.517 48.8163 240.404 48.2971 240.33 47.7715C239.985 45.4619 240.356 43.102 241.393 41.0102C242.429 38.9185 244.081 37.1954 246.125 36.0736C248.17 34.9517 250.508 34.4849 252.826 34.7362C255.143 34.9875 257.328 35.9448 259.085 37.479C259.319 37.678 259.544 37.8914 259.763 38.1163Z" fill="#85DB43"/>
<path d="M34.7482 131.816L34.5591 131.791L35.0204 133.548C35.4563 135.998 36.0894 138.41 36.9135 140.758L50.4407 192.275C50.8724 193.92 51.7739 195.402 53.0345 196.54C54.2951 197.678 55.86 198.423 57.5371 198.683L85.1236 202.959C86.9073 203.235 88.7328 202.95 90.3478 202.143C91.9628 201.335 93.2878 200.045 94.1398 198.451L120.356 149.394C121.175 148.034 121.924 146.634 122.598 145.197L123.489 143.532L123.35 143.513C126.129 136.927 127.352 129.787 126.924 122.649C126.496 115.511 124.429 108.569 120.883 102.363C117.337 96.1577 112.409 90.8563 106.482 86.8724C100.555 82.8885 93.7893 80.3297 86.7131 79.3955C79.6369 78.4614 72.441 79.177 65.6862 81.4868C58.9314 83.7965 52.8004 87.6379 47.7709 92.7115C42.7414 97.7851 38.9495 103.954 36.6906 110.737C34.4317 117.52 33.767 124.734 34.7482 131.816H34.7482Z" fill="white"/>
<path d="M86.8162 206.325C86.3303 206.325 85.8452 206.287 85.365 206.213L56.5295 201.743C54.698 201.46 52.989 200.647 51.6124 199.404C50.2358 198.161 49.2515 196.542 48.7803 194.746L34.6408 140.896C33.7766 138.431 33.1125 135.9 32.6549 133.328L32.2972 130.972C31.3352 123.446 32.1324 115.799 34.6261 108.634C37.1197 101.47 41.2415 94.9841 46.6661 89.6891C52.0906 84.3941 58.6694 80.4349 65.8832 78.1239C73.0969 75.8128 80.7481 75.2134 88.2328 76.3727L88.1079 77.1789C80.7048 76.0359 73.1375 76.6421 66.0097 78.9491C58.882 81.2561 52.391 85.2 47.0546 90.4663C41.7183 95.7325 37.6842 102.175 35.2743 109.281C32.8644 116.386 32.1454 123.957 33.1745 131.39L33.4494 133.153C33.9036 135.703 34.5621 138.213 35.4186 140.657L49.5679 194.539C49.9987 196.181 50.8987 197.661 52.1575 198.798C53.4164 199.934 54.9792 200.678 56.654 200.937L85.4895 205.407C87.2705 205.681 89.0926 205.395 90.705 204.589C92.3173 203.783 93.6406 202.496 94.4927 200.905L95.2107 201.29C94.3959 202.811 93.1852 204.082 91.7071 204.968C90.229 205.855 88.5389 206.324 86.8162 206.325Z" fill="#85DB43"/>
<path d="M94.1313 143.527L76.5859 191.521L77.5748 191.884L95.1202 143.889L94.1313 143.527Z" fill="#85DB43"/>
<path d="M65.4833 139.087L64.4312 139.133L66.6706 190.196L67.7227 190.149L65.4833 139.087Z" fill="#85DB43"/>
<path d="M84.3789 230.738L47.4296 225.011C47.1549 224.967 46.9088 224.815 46.745 224.59C46.5813 224.365 46.5133 224.083 46.5558 223.808C46.5984 223.532 46.748 223.285 46.9721 223.12C47.1961 222.954 47.4764 222.885 47.7516 222.926L84.7009 228.652C84.8383 228.673 84.9702 228.72 85.0891 228.792C85.2081 228.864 85.3117 228.958 85.394 229.07C85.4764 229.182 85.5358 229.309 85.5689 229.444C85.6019 229.58 85.6081 229.72 85.5868 229.857C85.5656 229.995 85.5175 230.127 85.4452 230.245C85.3729 230.364 85.2779 230.467 85.1656 230.549C85.0534 230.631 84.9261 230.69 84.7911 230.722C84.6561 230.755 84.516 230.76 84.3789 230.738Z" fill="#212121"/>
<path d="M85.3261 222.877L48.3768 217.151C48.2395 217.13 48.1075 217.083 47.9886 217.011C47.8697 216.939 47.766 216.845 47.6837 216.733C47.6014 216.621 47.542 216.493 47.5089 216.358C47.4758 216.223 47.4697 216.083 47.4909 215.946C47.5121 215.808 47.5603 215.676 47.6325 215.558C47.7048 215.439 47.7998 215.336 47.9121 215.254C48.0243 215.172 48.1516 215.113 48.2867 215.081C48.4217 215.048 48.5617 215.043 48.6988 215.065L85.6481 220.792C85.7854 220.812 85.9174 220.86 86.0363 220.931C86.1552 221.003 86.2589 221.098 86.3412 221.21C86.4235 221.322 86.4829 221.449 86.516 221.584C86.5491 221.719 86.5552 221.859 86.534 221.997C86.5128 222.134 86.4646 222.266 86.3923 222.385C86.3201 222.504 86.2251 222.607 86.1128 222.689C86.0006 222.771 85.8733 222.829 85.7383 222.862C85.6032 222.894 85.4632 222.899 85.3261 222.877Z" fill="#212121"/>
<path d="M86.274 215.016L49.3246 209.289C49.0499 209.245 48.8038 209.094 48.6401 208.868C48.4763 208.643 48.4083 208.362 48.4509 208.086C48.4934 207.811 48.6431 207.563 48.8671 207.398C49.0912 207.233 49.3714 207.163 49.6467 207.204L86.596 212.931C86.8707 212.975 87.1169 213.126 87.2806 213.352C87.4443 213.577 87.5123 213.858 87.4698 214.134C87.4272 214.409 87.2776 214.657 87.0535 214.822C86.8294 214.987 86.5492 215.057 86.2739 215.016H86.274Z" fill="#212121"/>
<path d="M64.7554 235.312C72.0845 236.448 78.6578 233.277 79.4373 228.229L52.8963 224.115C52.1167 229.163 57.4263 234.176 64.7554 235.312Z" fill="#212121"/>
<path d="M78.037 133.416C76.4605 133.172 75.1561 131.923 74.4411 131.086C74.2066 130.812 73.9784 130.527 73.7495 130.242C72.9167 129.204 72.1304 128.223 71.0354 127.711C68.6184 126.582 65.7705 128.16 63.3047 129.853L62.709 128.983C65.4199 127.121 68.5812 125.401 71.4808 126.755C72.7978 127.37 73.6991 128.494 74.5704 129.581C74.7923 129.858 75.0138 130.134 75.2409 130.4C75.9845 131.27 77.4674 132.68 79.0564 132.354C79.726 132.159 80.3487 131.829 80.8866 131.384C81.2963 131.077 81.7294 130.804 82.1818 130.565C83.0992 130.124 84.1263 129.965 85.1337 130.108C86.1411 130.251 87.084 130.689 87.8436 131.367C88.1143 131.624 88.3708 131.896 88.6119 132.181C89.0856 132.722 89.5329 133.232 90.1152 133.418C90.976 133.694 91.8857 133.201 92.8497 132.68C93.8439 132.143 94.8717 131.587 96.0375 131.724C96.0753 131.728 96.1135 131.734 96.1511 131.739C98.2256 132.061 99.1821 134.359 99.7232 136.075L98.7188 136.392C97.9834 134.061 97.0879 132.901 95.9141 132.772C95.079 132.673 94.2392 133.128 93.3501 133.608C92.2354 134.211 91.0818 134.835 89.7954 134.423C88.9344 134.149 88.3677 133.502 87.8201 132.877C87.6043 132.621 87.3748 132.377 87.1327 132.145C86.5277 131.615 85.7816 131.272 84.9853 131.159C84.1891 131.046 83.3774 131.169 82.6493 131.511C82.2461 131.725 81.8601 131.971 81.4946 132.245C80.841 132.779 80.0822 133.169 79.2678 133.388C78.8625 133.471 78.4456 133.48 78.037 133.416Z" fill="#85DB43"/>
</svg>



    )
}

export default AutonomieFigure;