import React from "react";

export const experimentation_left_title = <>
  Mon expérimentation
</>

export const experimentation_left_description = <>
  Je construis de nouveaux équilibres dans mon travail
</>

export const experimentation_intro = <>
  <b>Elaborez</b> des actions ou des façons de faire alternatives pour créer un nouvel équilibre et travailler en santé.
</>

export const experimentation_explanation = <>
  <b>Votre exploration progresse !</b><br/>
  <br/>
  Les notes inspirées par les lectures des récits, les pistes de réflexion que vous avez élaborées au cours de votre enquête… tout ceci est rassemblé dans cette section « expérimentation » dans laquelle vous pouvez affiner et suivre les pistes d’action à mettre en œuvre en lien avec votre environnement professionnel.
</>

export const experimentation_action_title  = <>
  A vous de tracer les contours de votre retour !
</>

export const experimentation_action_description = <>
  
  En partant de vos notes, vous pouvez maintenant introduire des changements dans la manière d’exercer votre activité. Si vous n’êtes pas seuls à décider de ces changements, les formuler constitue une première étape avant de pouvoir en discuter et les mettre en application.</>

export const experimentation_action_placeholder = "Ne vous censurez pas, lancez-vous ! Vos idées seront ajoutées dans des cartes “Expérimentation” modifiables à tout moment."

export const experimentation_resources = <>
  <b>Quelques ressources,</b><br/>
  pour <i>définir</i> de nouvelles stratégies de maintien ou retour d’activité
</>

export const empty_experimentation = <>
  <b>Cette section est vide (mais ça ne saurait durer !)</b><br/><br/>
  Voici ce que vous pouvez faire pour commencer à la remplir :
</>

export const empty_experimentation_stories = <>
  <b>Parcourez</b> des récits d’expériences, <b>surlignez</b>-en des passages, <b>annotez</b>-les...
</>

export const empty_experimentation_activities = <>
  <b>Explorez</b> les différents champs de <b>votre activité</b> pour déterminer ce qui est <b>à conserver</b> ou ce qui doit <b>évoluer</b>.
</>
