import React from "react";

export const barometer_left_title = <>
  Votre instantané
</>

export const barometer_left_description = <>
  Quelles sont les grandes caractéristiques de votre situation de travail ?
</>

export const barometer_intro = <>
  Pour le savoir, vous pouvez vous interroger sur des éléments factuels. Ce rapide tour d’horizon (25 questions, environ 5 minutes) permettra d’initialiser votre tableau de bord personnel. Vous obtiendrez un panorama global des 5 dimensions de votre activité, et pourrez identifier facilement celles que vous souhaitez approfondir en priorité.
  </>

export const barometer_question = <>
  <b>En ce moment, votre travail vous paraît...</b>
</>

export const old_barometers_title = <>
  Mes relevés de intantané
</>

export const barometer_start = <> 
</>

export const barometer_skip = <>
  Non merci, peut-être une autre fois
</>

export const barometer_button = <>
  Je réalise ma photo instantanée
</>

export const barometer_result_submit = <>
  Obtenez vos résultats
</>

export const barometer_finished= <>
  <h3 class="txt-dark text-left barometer-end">Vous avez réalisé une première photo instantanée de votre situation de travail. Vous pouvez explorer à présent plus en détail les différentes dimensions de votre activité</h3> 
</>

export const questions = [
  { key: 0,
    left: "Intéressant",
    right: "Peu passionant"
  },
  { key: 1,
    left: "Varié",
    right: "Monotone"
  },
  { key: 2,
    left: "Facile",
    right: "Trop complexe"
  },
  { key: 3,
    left: "Sans danger",
    right: "Dangereux"
  },
  { key: 4,
    left: "Utile",
    right: "Peu utile"
  },
  { key: 5,
    left: "Vous permet l'initiative",
    right: "Routinier"
  },
  { key: 6,
    left: "Vous laisse des temps de repos",
    right: "Ne vous offre aucun répit"
  },
  { key: 7,
    left: "Vous permet d'utiliser vos connaissances",
    right: "N'a guère de rapport avec vos connaissances"
  },
  { key: 8,
    left: "Vous donne conscience d'une responsabilité",
    right: "Vous n'avez pas l'impression d'être responsable"
  },
  { key: 9,
    left: "Est l'occasion de contacts fréquents avec d'autres membres du personnel",
    right: "N'offre guère d'occasion de contacts"
  },
  { key: 10,
    left: "Vous apporte des satisfactions personnelles",
    right: "Ne vous apporte pas de satisfactions personnelles"
  },
];
