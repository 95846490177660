import React from "react";

export const quizz_left_title = <>
  Analyser la dimension :
</>

export const quizz_left_description_after = <>
Voici les cartes “situation” qui ont retenu votre attention.  
</> 
export const quizz_left_description = <>
Comment ça marche ?
  </>
export const quizz_left_intro_after = <>
Passez les en revue une dernière fois, à tête reposée.  Modifiez vos réponses si nécessaire.  A l’issue de ce travail, synthétisez vos réflexions sur les 2 post-it présents en bas de page. C’est à vous !
  </>

export const quizz_intro = <>
 25 cartes vont défiler sous vos yeux.  Certaines situations professionnelles qu’elles décrivent  peuvent résonner en vous de façon positive ou négative.  Triez-les en fonction de vos ressentis.   Si certaines cartes ne sont pas adaptées à votre cas,  placez-les dans la corbeille. Plutôt simple, n’est-ce pas ? 
</>

export const quizz_question = <>
  <b>En ce moment, votre travail vous paraît...</b>
</>

export const quizz_start = <>
  Prêt à commencer l’exploration ? 
</>

export const quizz_skip = <>
  Non merci, peut-être une autre fois
</>

export const quizz_button = <>
  À vous de jouer !
</>

export const quizz_result_submit = <>
  Obtenez vos résultats
</>

export const quizz_finished= <>
  <h3 class="txt-dark text-left quizz-end">Félicitations ! </h3><h3 class="txt-dark text-left quizz-end">Vous êtes venu à bout des 25 questions du baromètre.</h3> 
</>

export const quizz_postits_describe = <>
  <h2 className="pb-2">Inventaire terminé ? Passez à la synthèse !</h2>
  <p>Appuyez vous sur les cartes “situation” précédemment triées pour rédiger une synthèse de votre ressenti.<br/>  Notez ce que vous souhaitez voir évoluer, mais aussi vos acquis, ce que vous souhaitez préserver.<br/>  Cette synthèse sera disponible et modifiable à tout moment sur votre tableau de bord.</p>
</>



export const tableau_header = <>
<p className="header-text">Nous sommes ravis de vous avoir avec nous !</p>
</>


