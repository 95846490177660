import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StoryBox from "./StoryBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {stories_filter_footer,} from "../../texts";

import Container from "react-bootstrap/Container";

const StoriesRelatedToActivities = () => {

  const {activityId} = useParams();
  const [title, setTitle] = useState("");
  const [stories, setStories] = useState([]);
  const [highlights, setHighlights] = useState(null);

  const[dimension, setDimension] = useState([]);
  console.log(activityId);
  useEffect(() => {
    axios.get(
    '//stories/activity/' + activityId
    ).then((res) => {
      setStories(res.data);
    }).catch((e) => {
      console.log(e);
    });
  }, []);
  useEffect(()=>{
    axios.get('/dimensions/').then((res) =>{
      res.data.forEach((e) => {
        if (e._id == activityId)
          setDimension(e.title);
        console.log(e);
      })
    })
    .finally(() => {});
  },[]);
  console.log(dimension);

  return <>
    <Container fluid={true} className="text-left pt-5">
    <div>
      <h4 className="px-2">Récits associés à la dimension <b> {dimension} </b></h4>
      <Link to={"/recits"}>
      <div className="link-back story-title row mt-5">
          <FontAwesomeIcon icon={faArrowLeft} size={"1x"} className="mt-1"/> <span className="ml-2 mt-0">Retourner à l'ensemble des récits</span>
        </div>
    </Link>
      <Row className="box-row">
        {stories.map((story, index) =>
          <Col xl={4} md={6} sm={12} key={"story-box-" + index}>
            <StoryBox {...story} highlights={highlights}/>
          </Col>
        )}
      </Row>
    </div>

    </Container>
  </>
}

export default StoriesRelatedToActivities;
