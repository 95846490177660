import React, {useRef,useState,useEffect} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import {ScrollTo} from "react-scroll-to";
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import arrow from "../../img/arrow-down-blue.svg";
import {
  barometer_intro,
  barometer_left_description,
  barometer_left_title,
  barometer_start,
  barometer_skip,
  barometer_button,
  barometer_result_submit,
  barometer_finished
} from "../../texts";
import 'rc-slider/assets/index.css';
import axios from 'axios';
import barometerHeader from "../../img/landing_barometer_new.svg";
import barometerfinished from "../../img/barometerfinished.svg";


const BarometerNew = () => {

  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState(['Pas du tout','Plutôt non','Plutôt oui','Tout à fait']);
  const [questionsTwo, setQuestionsTwo] = useState(null); 
  const [questionsThree, setQuestionsThree] = useState(null); 
  const [questionsFour, setQuestionsFour] = useState(null); 
  const [questionsFive, setQuestionsFive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [index,setIndex] = useState(0);
  const [answers,setAnswers] = useState(()=>new Map());
  const [testing, setTesting] = useState (false);
  const [finished, setFinished] = useState (false);
  const [scores, setScores] = useState(null);
  const history = useHistory();
  const [start, setStart] = useState(null);

  const [length, setLength] = useState([]);


  useEffect(()=> {
    axios.get(
      '/barometer/score',
    ).then((res) => {
      if (res.data.answers) {
        setTesting(true);
        console.log(testing)
      } else {
        console.log("Data is empty");
      }
    }).catch((e) => {
      console.log(e);
    })
  },[])

  const updateScores = () => {
    axios.put(
      '/barometer/score',{answers:scores}
    ).then((res) => {
      console.log("ic pk record scroe", res);
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(()=>{
    setLoading(true);
    axios.get('/questions/').then((res) =>{

      setLength(res.data.length);
      (res.data.length>0) ?
      res.data.forEach((e) => {
        setQuestions(prevState=>[...prevState,e]);
      })
      :
      console.log("do nothing")
    })
    .finally(() => {
      setLoading(false);
    }
    );
  },[])

  useEffect(()=>{
    if (scores !== null) {
      updateScores();
    } else {
      console.log("Can't log null values");
    }
  },[scores])

  const addAnswer = (id,v,c) => {
    setStart(true);
    (typeof(answers.get(id)) != "undefined") ? setAnswers(new Map(answers.set(id, {dimension:c,score:v}))) : setAnswers(map => new Map([...map,[id,{dimension:c,score:v}]]));
  }

  function setScore(totalScore){

  }
  const return_board = async () => {
      let x = await saveAnswer();
      history.push('/tableau')
  }
  const saveAnswer = () => {
    const getValue = (val) => {
      switch (val) {
        case 0: return Number(-1);
        /* falls through */
        case 1: return Number(-0.5);
        /* falls through */
        case 2: return Number(0.5);
        /* falls through */
        case 3: return Number(1);
        /* falls through */
        default: return Number(0);
      }
    }
    let sumAutonomy=0;
    let sumTemps=0;
    let sumCarriere=0;
    let sumRelationnel=0;
    let sumCondition=0;
    let totalScore=[];
    answers.forEach((value,key)=>{
      if (value.dimension === 'Autonomie') {
        sumAutonomy=sumAutonomy+Number(getValue(value.score));
      }
      if (value.dimension === 'Relationnel') {
        sumRelationnel=sumRelationnel+Number(getValue(value.score));
      }
      if (value.dimension === 'Temps') {
        sumTemps=sumTemps+Number(getValue(value.score));
      }
      if (value.dimension === 'Carrière') {
        sumCarriere=sumCarriere+Number(getValue(value.score));
      }
      if (value.dimension === 'Conditions') {
        sumCondition=sumCondition+Number(getValue(value.score));
      }
    
    });
    totalScore=[sumAutonomy,sumCondition,sumRelationnel,sumTemps,sumCarriere];
    setScores(totalScore);
    return 1;
    //return (history.push("/tableau"));
  }




  const renderOptions = () => {
    let options = [];
    return (
      <div>Hello</div>
    );
  }

  const start_function = async () => {
    setStart(true);
    setTesting(true);
  }
  if (loading) {
    return <p>Data is loading...</p>;
  }

  return (

      <div>
    <div className={start?"intro pb-5 d-none":"intro pb-5" }>

    <Container fluid={true}>
    <Row className="pb-5">
    <Col>
      <h1>{barometer_left_title}</h1>
      <p>{barometer_left_description}</p>
      <p>{barometer_intro}</p>
    </Col>
    <div className="intro-img">
      <img src={barometerHeader} alt="logo" className="intro-logo"/>
    </div>
    </Row>

  </Container>
    </div>
    { !start ?
      <>
      <div className="barometer-commence pb-5">
      <Row>
        <Col>
          <h2 className="mb-4 mt-5 story-display-title">{barometer_start}</h2>
          <button className="btn-first px-5 py-3" onClick={start_function}>{barometer_button}</button>
        </Col>
      </Row>
      <a href="/tableau" className="link-simple">{barometer_skip}</a>
    </div>
      </>
      :
      <>
    {!testing ? 
    <div className="barometer-commence pb-5">
      <Row>
        <Col>
          <h2 className="mb-4 mt-5 story-display-title">{barometer_start}</h2>
          <button className="btn-first px-5 py-3" onClick={()=>setTesting(true)}>{barometer_button}</button>
        </Col>
      </Row>
      <a href="/tableau" className="link-simple">{barometer_skip}</a>
    </div>
    :
    <div className="intro-dashboard pb-5 pt-5">

    <Container fluid={true}>
      <Row className="d-flex justify-content-center" width="100%">
            { index > 0?
              <>
              <div className="barometer-box-preview-left" height="">
                
                <h3 className="p2"><b>{questions[index-1]?.question}</b></h3>
              </div>
              </>
              :
              <></>
            }
              <div className="barometer-box-preview-main p-5">

                {!finished ?
                  <>
                  <h3 className="p2 pb-3">{questions[index]?.question}</h3>
                  {options.map((op,i) => {  
                               
                      if (answers.get(questions[index]?._id)?.score === Number(i)) {
                        return (
                          <div className="options-tags-answer mb-4" onClick={()=>{if (index<length-1) {setTimeout(function(){setIndex(old=>old+1)},300)};addAnswer(questions[index]?._id,i,questions[index]?.dimension);if (index===length-1) {setFinished(true)}}}>{op}</div>
                        )
                      } else {
                        return (
                          <div className="options-tags mb-4" onClick={()=>{if (index<length-1) {setTimeout(function(){setIndex(old=>old+1)},50)};addAnswer(questions[index]?._id,i,questions[index]?.dimension);if (index===length-1) {setFinished(true)}}}>{op}</div>  
                        )
                      }
                    }
                  )}
                  
                   <Row className="d-flex justify-content-between mb-0 pb-0 pt-5">
                   { index > 0?
                    <h5 onClick={()=>{if (index>=1) {setIndex(old=>old-1)}}}>Question précédente</h5>
                        :
                        <></>
                      }
                    <h3 className="txt-orange">{index+1}/{length}</h3>
                  </Row>
                  </>
                :
                  <>
                    <div className="intro-img">
                      <img src={barometerfinished} alt="logo" className="intro-logo"/>
                    </div>
                    <Row className="d-flex justify-content-center mb-0 pb-0">
                      <Col lg={12} className="text-left py-5">
                      {barometer_finished}
                      </Col>
                      <button className="btn pr-5 pl-5" onClick={()=>return_board()}>{barometer_result_submit}</button>
                      <Col lg={12} className="text-center pt-2">
                      <h5 onClick={()=>{if (index>=1) {setIndex(old=>old); setFinished(null)}}}><b>Question précédente</b></h5>
                
                      </Col>
                    </Row>                  </>
                }
                
              </div>
              { index >= length-1?
                <>
                </>
                :
              <>
              <div className="barometer-box-preview-right">
                <h3 className="p2 py-3"><b>{questions[index+1]?.question}</b></h3>
              </div>
              </>

            }
        </Row>
      </Container>
      
      </div>
      }
      </>

    }
      </div>
  )
}

export default BarometerNew;
