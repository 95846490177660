import {Link} from "react-router-dom";

export const account_login_left_title = <>
  Mon compte
</>

export const account_login_left_description = <>
  Je me connecte à mon espace sécurisé
</>

export const login_intro = <>
  A vous de découvrir dans quelles conditions votre activité peut devenir
  votre meilleure alliée.<br/><br/>

  Vous allez désormais entrer dans votre espace personnel, qui préserve
  vos données et votre identité, prêt.e ?
</>

export const cgu_agreement = <>
  J'ai lu et j'accepte les <Link to="/cgu" target={"_blank"}>Conditions Générales d'Utilisation</Link> du Site 9.3/4 et
  de ses services.
</>

export const pdc_agreement = <>
  Je m’engage à ce que les informations fournies soient exactes et consens au traitement de mes données personnelles
  par l'association Matrice dans le cadre de la <Link to="/pdc" target={"_blank"}>Politique de confidentialité</Link>.
  <br/>
  Elles seront utilisées pour permettre la création de mon compte sur le site 9 ¾, opérer et améliorer des
  fonctionnalités essentielles du site, ainsi qu’à des fins de recherche. Ces données seront stockées sur des serveurs
  OVH localisés en France et ne seront pas communiquées à des tiers sans être anonymisées. Elles seront supprimées au
  maximum un an après ma dernière connexion au site. Je peux à tout moment exercer un droit d'accès, de rectification
  et de suppression de ces données, en contactant <a href={"mailto:rgpd@matrice.io"}>rgpd@matrice.io</a>
</>

export const cgu_pdc_required = <>
  Votre consentement éclairé nous importe !<br/>

  Avant de nous envoyer ces informations, merci d'accepter nos Conditions générales d'utilisation et de consentir
  au traitement de vos données en cochant les cases suivantes.
</>

export const double_opt_in_mail_sent = <>
  <p><b>Validez votre adresse mail. Commencez l’expérience.</b></p><br/>
  Un lien de confirmation vient de vous être envoyé par e-mail.  <br/>Cliquez-le pour activer votre espace personnel.<br/><br/>
  <h3 style={{'color':'#212121'}}>Vous n’avez rien reçu ?</h3>
  Vérifiez le contenu du dossier “Courrier indésirable”.<br/>
  Toujours rien ? Merci de <a href={"mailto:contact@934-travail-sante.fr"}>nous contacter par e-mail</a>,
  afin que nous puissions vous venir en aide.
</>
