import React from "react";
import {login_intro} from "../../texts";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";


const Intro = (props) => {

  return <>
    <h5>{login_intro}</h5>
    <Link to={"/auth/" + props.from + "/signup"}>
      <Button className="btn btn-white">
        Je crée mon espace
      </Button>
    </Link>
    <p className="p1">Déja inscrit ? <Link to={"/auth/" + props.from + "/signin"}><u>Connectez-vous</u></Link></p>
  </>
}

export default Intro;
