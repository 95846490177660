import React, {useEffect, useState} from "react";
import axios from "axios";

const Legal = (props) => {

  const [content, setContent] = useState(null);

  useEffect(() => {
    axios.get(`/legal/${props.type}`).then((res) => setContent(res.data.content));
  }, [props]);

  return <div className={"legal"}>
    {content && <div dangerouslySetInnerHTML={{__html: content}}/>}
  </div>
}

export default Legal;
