import React from "react";

export const activity_left_title = <>
  Mon activité
</>

export const activity_left_description = <>
  J'explore les contours de mon activité
</>

export const activity_intro = <>
  <b>Questionner</b> la façon dont votre activité s’organise pour nourrir votre réflexion sur d’autres façons de travailler
</>

export const activity_explanation = <>
  Comment s’y prendre et par où commencer pour reprendre son travail ?<br/> 
  Répondre à ces questions ne se fait pas d’un coup de baguette magique. 
  A vous d’explorer les différents champs de votre activité pour déterminer des actions concrètes. 
</>

export const after_activity = <>
  <b>Votre exploration avance !</b><br/>
  Pour finir, passez en revue vos réponses afin d’identifier :<br/>
  - ce que vous voulez conserver<br/>
  - ce que vous voulez faire évoluer<br/>
  pour articuler travail et santé.<br/>
</>

export const activity_analysis_first_button = "à conserver";

export const activity_analysis_second_button = "à faire évoluer";

export const activity_analysis_first_placeholder = "Pourquoi souhaitez-vous conserver cet aspect de votre activité ?";

export const activity_analysis_second_placeholder = "Pourquoi souhaitez-vous faire évoluer cet aspect de votre activité ?";


export const after_analysis = <>
  <b>Beau travail !</b>
  <br/><br/>
  Vous êtes arrivé à la fin de cette exploration.
  Retrouvez les éléments que vous avez identifiés
  pour améliorer votre équilibre au travail
  dans la {/* Lien vers la section experimentation*/}
</>
