import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Button from "react-bootstrap/cjs/Button";
import Alert from "react-bootstrap/Alert";


const ForgotPassword = () => {

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState(false);

  const handleSubmit = (event) => {
    axios.post('/auth/recover', {
      email
    }).then((res) => {
      setMessage("Lien de réinitialisation envoyé à l'adresse " + email + ".");
    }).catch((e) => {
      setErrors("Aucun compte n'est associé à cette adresse.");
    });

    event.preventDefault();
  }

  return <Container fluid={true}>
    <Row>
      <Col lg={5}>
        <h3><b>Récupération du mot de passe</b></h3>
        <br/>
        {message && <Alert variant={"success"}>{message}</Alert>}
        {errors && <Alert variant={"danger"}>{errors}</Alert>}
        <form onSubmit={handleSubmit} className="auth-form">
          <input
            required
            type={"email"}
            placeholder={"Votre adresse email"}
            value={email}
            onChange={(event) => setEmail(event.target.value)} />
          <Button type={"submit"} className="btn btn-blue">Envoyer</Button>
        </form>
      </Col>
    </Row>
  </Container>
}

export default ForgotPassword;
