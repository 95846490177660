import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Modal from "react-bootstrap/cjs/Modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import heart from "../../img/heart.svg";
import img_notes from "../../img/notes.svg";
import heartFull from "../../img/heart_full.svg";
import share from "../../img/share.svg";
import highlighter from "../../img/highlighter.svg";
import chevronLeft from "../../img/chevron-left.svg";
import chevronRight from "../../img/chevron-right.svg";
import {slide as SideBar} from "react-burger-menu";
import {stories_footer, story_display_footer} from "../../texts";
import {Context} from "../../state/Store";
import {ADD_CONSULTED_STORY, ADD_FAVORITE_STORY, REMOVE_FAVORITE_STORY} from "../../state/Reducer";
import {TYPE_STORY} from "../Auth/Auth";
import {domain} from "../../texts/_params";
import heartMin from "../../img/heart_min.svg";
import notesMin from "../../img/notes_min.svg";

const StoryDisplay = () => {
  const { storyId } = useParams();
  const box = useRef(null);
  const [state, dispatch] = useContext(Context);
  const [story, setStory] = useState(null);
  const [hearted, setHearted] = useState(state.user?.favoriteStories.includes(storyId));
  const [highlights, setHighlights] = useState(null);
  const [testimony, setTestimony] = useState(null);
  const [highlightMode, setHighlightMode] = useState(false);
  const [lastHighlighted, setLastHighlighted] = useState(null);
  const [commentsSidebarOpen, setCommentsSidebarOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [commentedHighlight, setCommentedHighlight] = useState(null);
  const [showAccountModal, setShowAccountModal] = useState(false);


  const [notes, setnotes] = useState(null);
  const [modalNotes, setModalNotes] = useState(null);
  const [width, setWidth]   = useState(window.innerWidth);

  useEffect(() => {
    axios.get(
    '/story/get/' + storyId
    ).then((res) => {
      setStory(res.data);
    }).catch((e) => {
      console.log(e);
    });
  }, [storyId]);

  useEffect(() => {
    axios.get(
    '/storynotes/' + storyId
    ).then((res) => {
      if (res.data.length > 0) {
        setnotes(res.data[0])
        setComment(res.data[0].comments)
      }
    }).catch((e) => {
      console.log("test2 ",e);
    });
  }, [storyId]);
  useEffect(() => {
    if (state.user) {
      axios.get(
        '/storyHighlights/get/' + storyId,
      ).then((res) => {
        setHighlights(res.data);
      }).catch((e) => {
        console.log(e);
      });

      if (!state.user.consultedStories.includes(storyId))
        axios.post(
          '/story/consult/' + storyId
        ).then(() => {
          dispatch({
            type: ADD_CONSULTED_STORY,
            payload: storyId
          });
        }).catch((e) => {
          console.log(e);
        });
    }
    else setHighlights([]);
  }, [storyId]);

  useEffect(() => {
    window.addEventListener("keydown", ({_, keyCode}) => keyCode === 27 && setHighlightMode(false));
  });

  //display highlights on testimony
  const openNotes = () => {
    setModalNotes(true);
  }
  const closeNotes = () => {
    if (notes)
      setComment(notes.comments);
    setModalNotes(null);
  }
  const recordNotes = () => {
    if (notes)
    {
      axios.put(
          '/storynotes/' + notes._id, {comment}
        ).then(() => {
          notes.comments = comment;
          closeNotes();
        }).catch((e) => {
          console.log("not save", e);
        });
      console.log("not exist");
    }
    else {
      axios.post(
          '/storynotes/' + storyId, {comment}
        ).then(() => {
          closeNotes();
        }).catch((e) => {
          console.log("not save", e);
        });
    }
  }
  useEffect(() => {
    const Highlight = (props) => {
      const target = useRef(null);
      const [show, setShow] = useState(false);

      useEffect(() => {
        if (props.highlight._id === lastHighlighted) setShow(true);
      }, []);

      return(
        <>
          <mark className="highlighted-passage" ref={target} onClick={() => setShow(!show)} onDoubleClick={() => console.log('double')} index-starts-at={props.indexStartsAt}>
            {props.testimony.substring(props.highlight.startIndex, props.highlight.endIndex)}
          </mark>
          <Overlay
            target={target.current}
            show={show}
            placement={'top'}
            rootClose={true}
            rootCloseEvent={'click'}
            onHide={() => {
              setShow(false);
              setLastHighlighted(null);
            }}
          >
            <Popover id={`highlight-popover-` + props.highlight._id} className="highlight-popover">
              <Popover.Content>
                <ButtonGroup>
                  <Button variant={"dark"} onClick={() => removeHighlight(props.highlight._id)}><span>Supprimer</span></Button>
                </ButtonGroup>
              </Popover.Content>
            </Popover>
          </Overlay>
        </>
      )
    }

    const highlightTestimony = (testimony) => {
      if (highlights?.length > 0) {
        let highlightsTmp = [];
        highlights.forEach(h => {
          highlightsTmp.push({ ...h })
        });
        highlightsTmp.push({startIndex: 0, endIndex: 0});
        highlightsTmp.sort((a, b) => a.startIndex - b.endIndex);
        let contentBits = [];
        let startIndex = 0;
        for (let i = 1; i < highlightsTmp.length; i++) {
          contentBits.push(<span key={"text-" + i} index-starts-at={startIndex}>{testimony.substring(highlightsTmp[i - 1].endIndex, highlightsTmp[i].startIndex)}</span>);
          startIndex += highlightsTmp[i].startIndex - highlightsTmp[i - 1].endIndex;
          contentBits.push(
            <Highlight highlight={highlightsTmp[i]} testimony={testimony} key={"highlight-" + i} indexStartsAt={startIndex}/>
          );
          startIndex += highlightsTmp[i].endIndex - highlightsTmp[i].startIndex;
        }
        contentBits.push(<span key={"text-" + highlightsTmp.length} index-starts-at={startIndex}>{testimony.substring(highlightsTmp[highlightsTmp.length - 1].endIndex)}</span>);
        return contentBits;
      }
      else return [testimony];
    }

    if (story) {
      setTestimony(highlightTestimony(story.testimony.toString()));
    }
  }, [story, highlights, lastHighlighted]);

  const setFavorite = () => {
    if (state.user)
      axios.post(
    '/story/heart/' + storyId,
    {forRemove : hearted}
      ).then(res => {
        setHearted(res.data.hearted);
        dispatch({
          type: hearted ? REMOVE_FAVORITE_STORY : ADD_FAVORITE_STORY,
          payload: storyId
        });
      }).catch((e) => {
        console.log(e);
      });
    else if (!state.user) setShowAccountModal(true);
  }

  const handleShare = () => {
    if (state.user)
      document.location.href = "mailto:?subject="
        + encodeURIComponent(story.title)
        + "&body=" + encodeURIComponent(`${domain}/recits/${storyId}`);
    else if (!state.user) setShowAccountModal(true);
  }

  const addHighlight = (e) => {
    if (state.user && highlightMode) {
      const selection = window.getSelection();
      if (!selection.isCollapsed && selection.anchorNode.parentNode === selection.focusNode.parentNode) {
        e.preventDefault();
        let startIndex = selection.anchorOffset + parseInt(selection.anchorNode.parentElement.getAttribute("index-starts-at") || 0);
        let endIndex = selection.focusOffset + parseInt(selection.anchorNode.parentElement.getAttribute("index-starts-at") || 0);
        if (endIndex < startIndex) [startIndex, endIndex] = [endIndex, startIndex];
        saveHighlight(startIndex, endIndex, selection.toString());
      }
    }
  }

  const saveHighlight = (startIndex, endIndex, text) => {
    let test = 0;
    highlights.forEach(h =>{
      if (h.startIndex == startIndex || h.startIndex < startIndex && startIndex < h.endIndex)
      {        test = 1;
      }
    })
    if (test == 0)
    {
      axios.post(
    '/storyHighlights/save/' + storyId,
    {startIndex, endIndex, text}
      ).then(res => {
        setHighlights([...highlights, res.data]);
        setLastHighlighted(res.data._id);
      }).catch((e) => {
        console.log(e);
      })
  }
  }

  const removeHighlight = (highlightId) => {
    axios.post(
  '/storyHighlights/remove/' + highlightId
    ).then(res => {
      setHighlights(highlights.filter(h => h._id !== res.data._id));
    }).catch((e) => {
      console.log(e);
    })
  }

  const saveComments = () => {
    axios.post(
      '/storyHighlights/comments/save/' + commentedHighlight._id,
      {comment}
    ).then(res => {
      setCommentsSidebarOpen(false);
      // add comments to highlights stored in state
      setHighlights([...highlights.filter(h => h._id !== commentedHighlight._id), {...highlights.find(h => h._id === commentedHighlight._id), comments: res.data}])
    }).catch((e) => {
      console.log(e);
    })
  }

  const removeComment = (commentToRemove) => {
    axios.post(
      '/storyHighlights/comments/remove/' + commentedHighlight._id,
      {commentToRemove}
    ).then(res => {
      // remove comments from highlights stored in state
      setHighlights([...highlights.filter(h => h._id !== commentedHighlight._id), {...highlights.find(h => h._id === commentedHighlight._id), comments: res.data}]);
      setCommentedHighlight({...commentedHighlight, comments: res.data});
    }).catch((e) => {
      console.log(e);
    })
  }



  return <>
      {story && <>



        

        <div className="story mt-5">
          <Row className="story-title">
            <Col sm style={{display: "flex"}} className="align-bottom text-left"><h2 className="story-display-title">{ story.title }</h2></Col>
          </Row>
          <Row className="story-title">
                <Link to="/recits">
        <div className="link-back story-title row">
          <FontAwesomeIcon icon={faArrowLeft} size={"1x"} className="mt-1"/> <span className="ml-2 mt-0">{story_display_footer}</span>
        </div>
      </Link>
      </Row>
          <Row>


          { width > 1100?
            <>
          <Col lg={3} className="story-tools">
          
              { state.user?
                <>
              <a tabIndex={0} onClick={setFavorite} onKeyDown={(e) => e.key === 'Enter' && setFavorite()}>
                <span className="story-tools-item" >
                { hearted ? <img src={heartFull} alt={"heart"} width={50}/> : <img src={heart} alt={"heart"} width={50}/>} <br/><p>Enregistrer</p>
              </span>
              </a>
              <a tabIndex={0} onClick={() => state.user ? setHighlightMode(!highlightMode) : setShowAccountModal(true)}
                 onKeyDown={(e) => e.key === 'Enter' && (state.user ? setHighlightMode(!highlightMode) : setShowAccountModal(true))}>
                <span className="story-tools-item">
                  <img src={highlighter} alt={"highlight"} width={50} /> <br/><p>{highlightMode ? <b><u>Surligner</u></b> : "Surligner"}</p>
                </span>
              </a>
               <a tabIndex={0} onClick={openNotes} onKeyDown={(e) => e.key === 'Enter' && handleShare()}>
                <span className="story-tools-item">
                  <img src={img_notes} alt={"share"} width={50} /> <br/><p>Annoter</p>
                </span>
              </a>
              </>
              :
              <></>
            }
              <a tabIndex={0} onClick={handleShare} onKeyDown={(e) => e.key === 'Enter' && handleShare()}>
                <span className="story-tools-item">
                  <img src={share} alt={"share"} width={50} /> <br/><p>Partager</p>
                </span>
              </a>
            </Col>
            </>
            :
            <>
           <Row id="nav-mobile-absolute" className="mobile-nav justify-content-around mx-auto bg-white">
           <Col>
              <a tabIndex={0} onClick={setFavorite} onKeyDown={(e) => e.key === 'Enter' && setFavorite()}>
                <span className="story-tools-item" >
                { hearted ? <img src={heartFull} alt={"heart"} height={40}/> : <img src={heartMin} alt={"heart"} height={40}/>} <br/><p>Enregistrer</p>
              </span>
              </a>
              </Col>
              <Col>
               <a tabIndex={0} onClick={openNotes} onKeyDown={(e) => e.key === 'Enter' && handleShare()}>
                <span className="story-tools-item">
                  <img src={notesMin} alt={"share"} height={40} /> <br/><p>Annoter</p>
                </span>
              </a>
              </Col>
              <Col>
              <a tabIndex={0} onClick={handleShare} onKeyDown={(e) => e.key === 'Enter' && handleShare()}>
                <span className="story-tools-item">
                  <img src={share} alt={"share"} height={40} /> <br/><p>Partager</p>
                </span>
              </a>
              </Col>
            </Row>
            </>
          }
            <Col lg={9} className="story-content">
              <p className={highlightMode ? "highlightable highlight-mode" : "highlightable"} onMouseUp={e => addHighlight(e)} onTouchEnd={e => addHighlight(e)}>
                {testimony}
              </p>
              <Row>
<Row>
            <h2 className="pt-3">Autres récits ...</h2>
              {story.previous_id &&
                <Link to={"/recits/" + story.previous._id} className="story-box-link">
                  <Col ref={box}>
                    <div className="story-box-preview">
                      <h3 className="p2 py-2">{story.previous.title}</h3>
                      <p className="p1">{story.previous.intro}</p>
                    </div>
                  </Col>
                </Link> }
                {story.next &&
                <Link to={"/recits/" + story.next._id} className="story-box-link">
                  <Col ref={box}>
                    <div className="story-box-preview">
                      <h3 className="p2 py-2">{story.next.title}</h3>
                      <p className="p1">{story.next.intro}</p>
                    </div>
                  </Col>
                </Link>}
              </Row>

              </Row>
            </Col>

            
          </Row>
          <div className="pb-5 mx-auto">
          <Link to="/recits">
          <button className="btn pr-5 pl-5 my-4 mx-auto">Retour à la liste des récits</button>
          </Link>
          </div>
        </div>
        </>
      }

      <Modal
        show={modalNotes}
        onHide={() => {setModalNotes(false); closeNotes()}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-notes"
      >
        <Modal.Header closeButton className="bg-white p-3 rounded ">
                   <Row className="modal-notes mt-2 p-3 justify-content-between">
          <h2 className="mb-2">Annoter le récit</h2>
          <textarea className="comments-input w-100" value={comment} onChange={(event) => setComment(event.target.value)}/>         
          <Col>
          <button className="w-100 py-1 btn-second" onClick={closeNotes}>Annuler</button>
          </Col>
          <Col>
          <button className="w-100 py-1 btn-first" onClick={recordNotes}>Enregistrer</button>
          </Col>
          </Row>
        </Modal.Header>
      </Modal>

      <Modal
        show={showAccountModal}
        onHide={() => setShowAccountModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-account"
      >
        <Modal.Header closeButton>
          <span>Pour utiliser ces outils, <br/>merci de vous <Link to={"/auth/" + TYPE_STORY}>créer un compte</Link><br/>
          ou de vous <Link to={"/auth/" + TYPE_STORY + "/signin"}>connecter</Link></span>
        </Modal.Header>
      </Modal>
    </>
}

export default StoryDisplay;
