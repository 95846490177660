import React from "react";
import {useParams} from "react-router-dom";
import SignUp from "./SignUp";
import Login from "./Login";
import Intro from "./Intro";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/cjs/Button";

import {
  account_login_left_description,
  account_login_left_title,
  activity_left_description,
  activity_left_title, barometer_left_description, barometer_left_title,
  experimentation_left_description,
  experimentation_left_title, stories_left_description, stories_left_title
} from "../../texts";


export const TYPE_STORY = "recits";
export const TYPE_BAROMETER = "barometres";
export const TYPE_ACTIVITY = "activite";
export const TYPE_EXPERIMENTATION = "experimentation";
export const TYPE_ACCOUNT = "compte";


const Auth = () => {

  const { section, tab } = useParams();

  const renderLeft = () => {
    switch (section) {
      case TYPE_STORY:
        return 
      case TYPE_BAROMETER:
        return 
      case TYPE_ACTIVITY:
        return 
      case TYPE_EXPERIMENTATION:
        return 
      case TYPE_ACCOUNT:
        return 
    }
  }

  const renderLogin = () => {
    let button;
    switch (section) {
      case TYPE_STORY:
      case TYPE_ACCOUNT:
        button = <Button type={"submit"} className="btn btn-blue">Valider</Button>;
        return <Login from={section} button={button} />
      case TYPE_BAROMETER:
        button = <Button type={"submit"} className="btn btn-light-blue">Valider</Button>;
        return <Login from={section} button={button} />
      case TYPE_ACTIVITY:
        button = <Button type={"submit"} className="btn btn-yellow">Valider</Button>;
        return <Login from={section} button={button} />
      case TYPE_EXPERIMENTATION:
        button = <Button type={"submit"} className="btn btn-green">Valider</Button>;
        return <Login from={section} button={button} />
    }
  }

  const renderSignUp = () => {
    let button;
    switch (section) {
      case TYPE_STORY:
      case TYPE_ACCOUNT:
        button = <Button type={"submit"} className="btn btn-blue">Valider</Button>;
        return <SignUp from={section} button={button} />
      case TYPE_BAROMETER:
        button = <Button type={"submit"} className="btn btn-light-blue">Valider</Button>;
        return <SignUp from={section} button={button} />
      case TYPE_ACTIVITY:
        button = <Button type={"submit"} className="btn btn-yellow">Valider</Button>;
        return <SignUp from={section} button={button} />
      case TYPE_EXPERIMENTATION:
        button = <Button type={"submit"} className="btn btn-green">Valider</Button>;
        return <SignUp from={section} button={button} />
    }
  }


  return <div className="intro">
<Container fluid={true}>
    <Row style={{minHeight: "100vh"}}>
      {renderLeft(section)}
      <Col className="col-centered col-full-height">
        {tab === "signin" ? renderLogin() : tab === "signup" ? renderSignUp() : <Intro from={section}/>}
      </Col>
    </Row>
  </Container>
  </div>
}

export default Auth;
