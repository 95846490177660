import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import {UNSET_USER} from "../../state/Reducer";
import Button from "react-bootstrap/cjs/Button";
import {Context} from "../../state/Store";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/cjs/Modal";
import {double_opt_in_mail_sent} from "../../texts";
import account_img from "../../img/account.svg";
import pencil from "../../img/pencil.svg";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
const Account = () => {

const history = useHistory();
const [state, dispatch] = useContext(Context);
const [email, setEmail] = useState(state.user?.email);
const [name, setName] = useState(state.user?.firstName);
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [errors, setErrors] = useState("");
const [result, setResult] = useState("");
const [show, setShow] = useState(false);

const handleSubmit = (event) => {

if (password === confirmPassword) {
if (password) {
axios.post('/auth/change-password', {
password
}).then((res) => {
setErrors("");
setResult("Enregistré !");
}).catch((e) => {
setErrors("Erreur serveur.");
});
}

axios.post('/auth/edit-info', {
email,
firstName: name,
}).then((res) => {
setErrors("");
setResult("Enregistré !");
if (email !== state.user.email) {
setShow(true);
}
}).catch((e) => {
setErrors("Erreur serveur.");
});
}
else setErrors("Les mots de passe de correspondent pas.");

event.preventDefault();
}

const logOut = (event) => {
axios.post('/auth/logout')
.then((res) => {
dispatch({type: UNSET_USER});
history.push("/");
}).catch((e) => {

});

event.preventDefault();
}

return <div className="intro">
  <Container fluid={true}>
    <Row className="justify-center">
      <Col lg={6} className="my-auto"><div className="intro-img">
        <img src={account_img} alt="logo" className="intro-logo"/>
      </div>
    </Col>
    <Col lg={6} className="auth-block px-3 my-4 align-self-end">
    <h2><b>Mon compte</b></h2>

    <h5>Modifier les informations</h5>
    {result && <Alert variant={"success"}>{result}</Alert>}
    {errors && <Alert variant={"danger"}>{errors}</Alert>}
    <Accordion classname="account" defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle eventKey="1" className="toogle-account">
            Prénom actuel: {name} <img width="30px;" src={pencil}/>
        </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
        <form onSubmit={handleSubmit} className="auth-form">
          <label>Entrez un nouveau prénom</label>
          <input
          type={"text"}
          value={name}
          onChange={(event) => setName(event.target.value)} />
          <input className="d-none" 
          type={"email"}
          value={email}
          onChange={(event) => setEmail(event.target.value)} />
          <input className="d-none"
          type={"password"}
          placeholder={"Mot de passe"}
          value={password}
          onChange={(event) => setPassword(event.target.value)} />
          <input className="d-none"
          type={"password"}
          placeholder={"Confirmer le mot de passe"}
          value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
          <div classname="w-100 text-center">
          <Button type={"submit"} className="btn-first btn-full">Valider</Button>
        </div>
        </form>
    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
        <Card.Header>
          <Accordion.Toggle eventKey="2" className="toogle-account">
            Email: {email} <img width="30px;" src={pencil}/>
        </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
        <form onSubmit={handleSubmit} className="auth-form">
          <input className="d-none" 
          type={"text"}
          value={name}
          onChange={(event) => setName(event.target.value)} />

          <label>Entrez votre nouvel email</label>
          <input 
          type={"email"}
          value={email}
          onChange={(event) => setEmail(event.target.value)} />
          <input className="d-none"
          type={"password"}
          placeholder={"Mot de passe"}
          value={password}
          onChange={(event) => setPassword(event.target.value)} />
          <input className="d-none"
          type={"password"}
          placeholder={"Confirmer le mot de passe"}
          value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
          <div classname="w-100 text-center">
          <Button type={"submit"} className="btn-first btn-full">Valider</Button>
        </div>
        </form>
    </Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
        <Card.Header>
          <Accordion.Toggle eventKey="3" className="toogle-account">
            Modifier le mot de passe <img width="30px;" src={pencil}/>
        </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
        <form onSubmit={handleSubmit} className="auth-form">
          <input className="d-none" 
          type={"text"}
          value={name}
          onChange={(event) => setName(event.target.value)} />

          <input  className="d-none"
          type={"email"}
          value={email}
          onChange={(event) => setEmail(event.target.value)} />

          <label>Entrez votre nouveau mot de passe</label>
          <input
          type={"password"}
          placeholder={"Mot de passe"}
          value={password}
          onChange={(event) => setPassword(event.target.value)} />

          <label>Confirmez votre nouveau mot de passe</label>
          <input 
          type={"password"}
          placeholder={"Confirmer le mot de passe"}
          value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
          <div classname="wrap">
          <Button type={"submit"} className="btn-first btn-full">Valider</Button>
        </div>
        </form>
    </Card.Body>
  </Accordion.Collapse>
</Card>
</Accordion>

<Button className="btn-first btn-full mt-3" onClick={logOut}>Déconnexion</Button>
</Col>
</Row>

<Modal centered show={show} onHide={() => {
  setShow(false);
  dispatch({type: UNSET_USER});
  history.push("/");
}}>
<Modal.Header closeButton>
<Modal.Title>Email modifié!</Modal.Title>
</Modal.Header>
<Modal.Body>
{double_opt_in_mail_sent}
</Modal.Body>
</Modal>
</Container>
</div>
}

export default Account;
