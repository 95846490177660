import React, {useState, useEffect, useContext} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import {SET_USER} from "../../state/Reducer";
import {Context} from "../../state/Store";
import Button from "react-bootstrap/cjs/Button";


const ConfirmEmail = () => {

  const {token} = useParams();
  const [, dispatch] = useContext(Context);
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    axios.post('/auth/confirm/' + token)
      .then((res) => {
        dispatch({type: SET_USER, payload: res.data.user});
        setMessage("Vous êtes maintenant connecté!");
      }).catch(() => {
        setErrors("Erreur.")
      });
  }, []);

  return <div className={"confirm-email"}>
    {message && <Alert variant={"success"}>{message}</Alert>}
    {errors && <Alert variant={"danger"}>{errors}</Alert>}
    <Link to={"/"}>
      <Button className="btn btn-blue">
        Aller à l'accueil
      </Button>
    </Link>
  </div>
}

export default ConfirmEmail;
